// src/components/Results.js

import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  CardMedia,
} from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { Skeleton } from "@mui/material";
import { CircularProgress } from "@mui/material"; // Add this import if not present
import { useMediaQuery, useTheme } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import CommentIcon from "@mui/icons-material/Comment";
import HomeIcon from "@mui/icons-material/Home";

import { darken } from "@mui/system";

/**
 * ImageCarouselWithDetails Component
 * Displays a carousel of stay listings with details and total stay price.
 */

// Helper function to format date and time
const formatDateTime = (dateString) => {
  const options = { month: "short", day: "numeric", year: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const formatTime = (dateString) => {
  const options = { hour: "2-digit", minute: "2-digit", hour12: false };
  return new Date(dateString).toLocaleTimeString(undefined, options);
};

// Helper function to check if arrival is the next day
const isNextDay = (departureDate, arrivalDate) => {
  const dep = new Date(departureDate);
  const arr = new Date(arrivalDate);
  return dep.getDate() !== arr.getDate();
};

// Helper function to format duration from minutes to hours and minutes
const formatDuration = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours} hr${hours !== 1 ? "s" : ""} ${mins} min${mins !== 1 ? "s" : ""}`;
};

const ImageCarouselWithDetails = ({
  listings = [], // Default to empty array if undefined
  departureDate,
  returnDate,
  totalStayPrice, // New prop for total stay price
}) => {
  const [currentListing, setCurrentListing] = useState(
    listings.length > 0 ? listings[0] : null
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile view

  const handleCarouselChange = (index) => {
    setCurrentListing(listings[index]);
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
    }).format(date);
  };
  if (!listings || listings.length === 0) {
    return (
      <Box sx={{ textAlign: "center", mt: 3 }}>
        <Typography variant="h5" sx={{ color: "text.secondary", mb: 2 }}>
          No listings available for the selected stay type.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{ width: "100%", maxWidth: "500px", mx: "auto", textAlign: "center" }}
    >
      {/* Display Stay Dates */}
      <Typography variant="body1" sx={{ mb: 1, color: "text.secondary" }}>
        {departureDate && returnDate
          ? `${formatDate(departureDate)} - ${formatDate(returnDate)}`
          : "Dates not set"}
      </Typography>

      {/* Listing Name */}
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          fontWeight: "bold",
          fontSize: { xs: "1.2rem", sm: "1.2rem" },
          mb: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          maxWidth: { xs: "300px", sm: "350px" }, // Allow wider text at xs, limit at sm+
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {currentListing.name}
      </Typography>

      {/* Pricing Details */}
      <Typography variant="body1" sx={{ mb: 0 }}>
        {(() => {
          // Check if 'total' is null and only 'rate' is provided (likely a monthly rate)
          if (
            currentListing?.price?.total === null &&
            currentListing?.price?.rate
          ) {
            const estimatedNightlyRate = Math.round(
              currentListing.price.rate / 30
            );
            const nights = Math.ceil(
              (new Date(returnDate) - new Date(departureDate)) /
                (1000 * 60 * 60 * 24)
            );
            const estimatedTotal = estimatedNightlyRate * nights;

            return `$${estimatedNightlyRate}/night x ${nights} nights = $${estimatedTotal}`;
          }

          // Regular calculation for listings with a valid 'total'
          const nightsInfo = currentListing.price.priceItems?.find((item) =>
            item.title.includes("nights")
          );
          const numberOfNights = nightsInfo
            ? parseInt(nightsInfo.title.match(/\d+ (?=nights)/)?.[0], 10)
            : Math.ceil(
                (new Date(returnDate) - new Date(departureDate)) /
                  (1000 * 60 * 60 * 24)
              );

          const ratePerNight = numberOfNights
            ? Math.round(currentListing.price.total / numberOfNights)
            : currentListing.price.rate;

          const formattedRatePerNight = new Intl.NumberFormat("en-US").format(
            ratePerNight
          );
          const formattedTotalPrice = new Intl.NumberFormat("en-US").format(
            Math.round(currentListing.price.total)
          );

          return `$${formattedRatePerNight}/night x ${numberOfNights} nights = $${formattedTotalPrice}`;
        })()}
      </Typography>
      {/* Rating and Reviews */}
      <Typography
        variant="body2"
        sx={{
          color: "text.secondary",
          fontSize: "1rem",
          fontWeight: "light",
          mt: 0,
          mb: 1,
          display: "flex",
          alignItems: "center", // Align icon and text
          justifyContent: "center", // Center horizontally
          gap: "2px", // Space between the icon and text
        }}
      >
        {currentListing.rating && currentListing.reviewsCount ? (
          <>
            <StarIcon sx={{ fontSize: "1rem" }} /> {/* Gold star */}
            {`${currentListing.rating} (${currentListing.reviewsCount} reviews)`}
          </>
        ) : (
          "No reviews available"
        )}
      </Typography>

      {/* Carousel of Stay Listings */}
      <Carousel
        showThumbs={false}
        infiniteLoop
        autoPlay
        interval={2000}
        transitionTime={600}
        showStatus={false}
        onChange={handleCarouselChange}
        centerMode={true}
        centerSlidePercentage={100}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              style={{
                position: "absolute",
                left: isMobile ? 5 : 15, // Adjust for mobile
                top: "50%",
                transform: "translateY(-50%)",
                background: "rgba(0, 0, 0, 0.5)", // Background color
                color: "white", // Arrow color
                border: "none",
                borderRadius: "50%",
                width: "40px", // Arrow size
                height: "40px",
                fontSize: "20px", // Arrow icon size
                cursor: "pointer",
                zIndex: 2,
              }}
            >
              ‹
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              style={{
                position: "absolute",
                right: isMobile ? 5 : 15, // Adjust for mobile
                top: "50%",
                transform: "translateY(-50%)",
                background: "rgba(0, 0, 0, 0.5)", // Background color
                color: "white", // Arrow color
                border: "none",
                borderRadius: "50%",
                width: "40px", // Arrow size
                height: "40px",
                fontSize: "20px", // Arrow icon size
                cursor: "pointer",
                zIndex: 2,
              }}
            >
              ›
            </button>
          )
        }
      >
        {listings.map((listing, index) => (
          <div key={index} style={{ position: "relative" }}>
            <img
              src={listing.images[0].replace(/^"|"$/g, "")}
              href={listing.deeplink}
              alt={`Listing ${index + 1}`}
              style={{
                width: "100%",
                height: "300px",
                objectFit: "cover",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={() => window.open(listing.deeplink, "_blank")}
            />
          </div>
        ))}
      </Carousel>

      {/* Total Stay Price Typography */}
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          fontSize: "4rem",
          mr: 0,
          mt: 2,
          mb: 0,
        }}
      >
        {`$${new Intl.NumberFormat("en-US").format(totalStayPrice)}`}
      </Typography>

      {/* View on AirBnB Button */}
      {currentListing && (
        <Box sx={{ mt: 1, textAlign: "center" }}>
          <Button
            href={currentListing.deeplink}
            target="_blank"
            rel="noopener noreferrer"
            // endIcon={<HotelIcon />} // Add the icon here
            sx={{
              mt: 0,
              mb: 2,
              backgroundColor: "#FF5A5F", // Airbnb's signature color
              color: "white",
              fontWeight: "bold",
              borderRadius: "8px",
              gap: "6px",
              padding: "12px 24px", // Increase padding for better look
              fontSize: "1.2rem",
              transition: "all 0.3s ease-in-out",
              textTransform: "none", // Keep the button text as-is
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", // Add subtle shadow for depth
              "&:hover": {
                backgroundColor: "#E14850", // Darker shade on hover
                boxShadow: "0 6px 16px rgba(0, 0, 0, 0.3)", // Stronger shadow on hover
                transform: "scale(1.05)", // Slight zoom effect on hover
              },
            }}
          >
            View on AirBnB
            <img
              src="/logo-airbnb.svg" // Reference from the public folder
              alt="Airbnb Logo"
              style={{ width: "24px", height: "24px" }}
            />
          </Button>
        </Box>
      )}
    </Box>
  );
};

/**
 * Results Component
 * Displays flight and stay results.
 */
const Results = ({
  bestItinerary,
  cheapestItinerary,
  fastestItinerary,
  flightResults,
  sortBy,
  handleSortChange,
  generateSkyScannerLink,
  quartiles,
  selectedStayCategory,
  handleStayCategoryChange,
  economyListings,
  standardListings,
  premiumListings,
  handleBackToSearch,
  departureDate,
  returnDate,
  handleClearStayFilter,
  loading,
  airbnbType,
  noAirbnbFound,
  noFlightsFound,
  submittedOrigin,
  submittedDestination,
  searchType,
  minReviewCount,
  minReviewScore,
  ...props
}) => {
  const [showSkeleton, setShowSkeleton] = useState(false);
  // Helper function to get the filter label
  const getFilterLabel = (type, minReviewCount, minReviewScore) => {
    const filters = [];

    const parsedReviewScore = parseFloat(minReviewScore);

    // Include minimum review count
    if (minReviewCount !== 5) {
      filters.push(
        <Box
          key="reviews"
          sx={{ display: "inline-flex", alignItems: "center", gap: 0.5 }}
        >
          > {minReviewCount} <CommentIcon sx={{ fontSize: "1rem" }} />
        </Box>
      );
    }

    // Include minimum review score
    if (!isNaN(parsedReviewScore) && parsedReviewScore !== 4.0) {
      filters.push(
        <Box
          key="rating"
          sx={{ display: "inline-flex", alignItems: "center", gap: 0.5 }}
        >
          > {parsedReviewScore.toFixed(2)}{" "}
          <StarIcon sx={{ fontSize: "1rem" }} />
        </Box>
      );
    }

    // Include Airbnb type filter
    if (type === "entire_home") {
      filters.push(
        <Box
          key="entire_home"
          sx={{ display: "inline-flex", alignItems: "center", gap: 0.5 }}
        >
          Entire Homes <HomeIcon sx={{ fontSize: "1rem" }} />
        </Box>
      );
    } else if (type === "private_room") {
      filters.push(
        <Box
          key="private_room"
          sx={{ display: "inline-flex", alignItems: "center", gap: 0.5 }}
        >
          Private Rooms <HomeIcon sx={{ fontSize: "1rem" }} />
        </Box>
      );
    }

    // Return the filters with separators if there are active filters
    return filters.length > 0 ? (
      <>
        {filters.map((filter, idx) => (
          <React.Fragment key={idx}>
            {filter}
            {idx < filters.length - 1 && (
              <Typography
                component="span"
                sx={{
                  margin: "0 8px",
                  color: "white",
                  fontSize: "1rem",
                  fontWeight: 300,
                }}
              >
                |
              </Typography>
            )}
          </React.Fragment>
        ))}
      </>
    ) : (
      ""
    );
  };

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setShowSkeleton(true);
      }, 2000); // Show skeleton after 2 seconds

      return () => clearTimeout(timer); // Cleanup on unmount
    } else {
      setShowSkeleton(false);
    }
  }, [loading]);

  if (loading && !showSkeleton) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%", // Fill the parent Card's height
        }}
      >
        <CircularProgress size={100} thickness={5} color="white" />
        <Typography
          variant="h6"
          sx={{
            mt: 2,
            color: "white", // Make the text white
            textAlign: "center", // Center align the text
          }}
        >
          Searching...
        </Typography>
      </Box>
    );
  }

  if (loading && showSkeleton) {
    return (
      <Box sx={{ mt: 5 }}>
        <Skeleton
          variant="rectangular"
          height={150}
          sx={{ mb: 3 }}
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          height={150}
          sx={{ mb: 3 }}
          animation="wave"
        />
        <Skeleton variant="text" height={40} width="60%" sx={{ mx: "auto" }} />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "column", md: "row" }, // Responsive layout
        justifyContent: { xs: "center", md: "space-between" }, // Center on small, space out on larger
        alignItems: { xs: "center", md: "flex-start" }, // Center items horizontally on small screens
        gap: 3,
        mt: 5,
        maxWidth: "1200px", // Limit the total container width
        width: "100%", // Ensure full width on smaller screens
        mx: "auto", // Center the container horizontally
      }}
    >
      {/* Flights Card */}
      {searchType !== "staysOnly" && (
        <Card
          sx={{
            flex: 1,
            maxWidth: { xs: "360px", md: "50%" }, // Adjust width for responsiveness
            minWidth: { xs: "360px", md: "50%" }, // Minimum width for smaller screens
            minheight: loading ? "600px" : "830px", // Explicitly set height dynamically
            p: 0.5, // Padding around the card
            borderRadius: "12px",
            boxShadow: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start", // Align content to the top
          }}
        >
          <CardContent>
            {/* Header Section: Logo and Title */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center", // Align vertically
                justifyContent: "center", // Center horizontally
                gap: 1.5, // Space between elements
                mb: 2, // Margin bottom for spacing
                width: "100%",
                maxWidth: "inherit", // Prevent any internal width restriction
                mt: 1, // Margin top for spacing
              }}
            >
              {/* Flight Icon */}
              <img
                src="flight-icon.svg" // Replace with your flight icon path
                alt="Flight Logo"
                style={{
                  width: "90px",
                  height: "60px",
                  objectFit: "contain",
                }}
              />
              {/* Title */}
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center", // Center the text
                }}
              >
                Flights
              </Typography>
            </Box>
            {/* Flight Results or Skeletons During Loading */}
            {loading ? (
              <>
                <Skeleton
                  variant="rectangular"
                  width="90%"
                  height={50}
                  sx={{ mb: 2 }}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  width="60%"
                  height={30}
                  sx={{ mb: 1 }}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  width="80%"
                  height={20}
                  sx={{ mb: 1 }}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={200}
                  sx={{ mb: 2 }}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  width="40%"
                  height={40}
                  sx={{ mb: 2 }}
                  animation="wave"
                />
              </>
            ) : flightResults?.length > 0 ? (
              flightResults.map((itinerary, index) => (
                <Box key={`flight-${index}`} sx={{ mb: 3, width: "100%" }}>
                  {/* Sorting Options */}
                  <ToggleButtonGroup
                    value={sortBy}
                    exclusive
                    onChange={(e, newValue) => {
                      if (newValue !== null) {
                        handleSortChange(newValue); // Update state on change
                      }
                    }}
                    sx={{
                      mb: 4,
                      borderRadius: "8px", // Rounded edges
                      backgroundColor: "#00000", // Light background
                      padding: "1px", // Compact padding
                    }}
                  >
                    {["best", "cheap", "fastest"].map((option, idx) => {
                      const titles = {
                        best: "Best",
                        cheap: "Cheapest",
                        fastest: "Fastest",
                      };
                      const priceOptions = {
                        best: bestItinerary?.price?.raw,
                        cheap: cheapestItinerary?.price?.raw,
                        fastest: fastestItinerary?.price?.raw,
                      };
                      return (
                        <Box
                          key={idx}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            borderRadius: "8px",
                          }}
                        >
                          <ToggleButton
                            value={option}
                            sx={{
                              fontSize: "0.875rem", // Compact font size
                              padding: "6px 16px", // Adjust height and width
                              display: "flex",
                              flexDirection: "column", // Stack vertically
                              alignItems: "center",
                              justifyContent: "center",
                              "&.Mui-selected": {
                                backgroundColor: "#1976d2", // Highlight color
                                color: "white",
                                "&:hover": {
                                  backgroundColor: "#1976d2", // Slightly darker hover for flights
                                },
                              },
                              "&:focus": {
                                backgroundColor: "#1976d2", // Keep selected color on focus
                              },
                              "&:active": {
                                backgroundColor: "#1976d2", // Active state for touch feedback
                              },
                            }}
                          >
                            <Typography
                              variant="button"
                              sx={{ fontWeight: "bold", marginBottom: "2px" }}
                            >
                              {titles[option]}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{ color: "grey.300" }}
                            >
                              $
                              {priceOptions[option]
                                ? new Intl.NumberFormat("en-US").format(
                                    Math.ceil(priceOptions[option])
                                  )
                                : "N/A"}
                            </Typography>
                          </ToggleButton>
                        </Box>
                      );
                    })}
                  </ToggleButtonGroup>
                  {/* Iterate Each Leg */}
                  {itinerary.legs.map((leg, idx) => (
                    <Box
                      key={`leg-${idx}`}
                      sx={{
                        mt: 2,
                        mb: 3,
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      {/* Cities */}
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                        }}
                      >
                        {leg.origin.city} to {leg.destination.city}
                      </Typography>

                      {/* Carrier Info */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mt: 1,
                          mb: 1,
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={leg.carriers.marketing[0]?.logoUrl}
                          alt={leg.carriers.marketing[0]?.name}
                          sx={{ width: 30, height: 30, mr: 2 }}
                        />
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", mr: 2 }}
                        >
                          {leg.carriers.marketing[0]?.name}
                        </Typography>
                        {leg.stopCount === 0 && (
                          <Typography
                            variant="body2"
                            sx={{
                              color: "text.secondary",
                              fontWeight: "bold",
                            }}
                          >
                            {leg.segments[0].operatingCarrier?.alternateId}
                            {leg.segments[0].flightNumber}
                          </Typography>
                        )}
                      </Box>

                      {/* Timing and Stops */}
                      <Typography variant="body1">
                        {formatDateTime(leg.departure)} -{" "}
                        {formatTime(leg.departure)} → {formatTime(leg.arrival)}
                        {isNextDay(leg.departure, leg.arrival) && " (+1)"}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Total Duration: {formatDuration(leg.durationInMinutes)}
                      </Typography>

                      {/* Stops */}
                      {leg.stopCount > 0 && (
                        <Box
                          sx={{
                            mt: 1,
                            pl: 2,
                            borderLeft: "2px solid #ddd",
                          }}
                        >
                          <Typography variant="subtitle1">
                            Stops: {leg.stopCount}
                          </Typography>
                          {leg.segments.map((segment, segIndex) => (
                            <Box
                              key={`segment-${segment.origin.displayCode}-${segIndex}`}
                              sx={{ mt: 1 }}
                            >
                              <Typography variant="body2">
                                {segment.origin.parent.name} (
                                {segment.origin.displayCode}) →{" "}
                                {segment.destination.parent.name} (
                                {segment.destination.displayCode})
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  ))}

                  {/* Pricing + CTA */}
                  <Box
                    sx={{
                      textAlign: "center",
                      mt: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", fontSize: "4rem", mt: 3 }}
                    >
                      {itinerary.price?.formatted || "N/A"}
                    </Typography>
                    <Button
                      onClick={() =>
                        window.open(generateSkyScannerLink(itinerary), "_blank")
                      }
                      sx={{
                        mt: 0,
                        backgroundColor: "#07a1fc",
                        color: "white",
                        fontWeight: "bold",
                        borderRadius: "8px",
                        px: 3,
                        py: 1.5,
                        transition: "all 0.3s ease",
                        "&:hover": {
                          backgroundColor: "#005fb8",
                          transform: "scale(1.05)",
                        },
                      }}
                    >
                      Book on SkyScanner
                      <img
                        src="/logo-flight.svg" // Public assets
                        alt="SkyScanner"
                        style={{ width: "25px", marginLeft: "10px" }}
                      />
                    </Button>
                  </Box>
                </Box>
              ))
            ) : noFlightsFound ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  minHeight: "600px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    mt: 2,
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  No flights found between <br></br>
                  {submittedOrigin?.city || "your origin"} and{" "}
                  {submittedDestination?.city || "your destination"} on these
                  dates.
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleBackToSearch}
                  sx={{
                    width: 150,
                    height: 50,
                    mt: 3,
                    backgroundColor: "#07a1fc",
                    color: "white",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: (theme) => darken("#07a1fc", 0.2), // Darken by 20%
                    },
                  }}
                >
                  Edit Search
                </Button>
              </Box>
            ) : (
              // Keep the loading flights fallback as the "else" case
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  minHeight: "600px",
                }}
              >
                <CircularProgress size={60} thickness={4} color="primary" />
                <Typography
                  variant="h6"
                  sx={{
                    mt: 2,
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Loading flights...
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      )}

      {/* Stays Card */}
      <Card
        sx={{
          flex: searchType === "staysOnly" ? "none" : 1, // Ignore flex when staysOnly
          maxWidth:
            searchType === "staysOnly"
              ? { xs: "360px", md: "75%" }
              : { xs: "360px", md: "50%" },
          minWidth:
            searchType === "staysOnly"
              ? { xs: "360px", md: "75%" }
              : { xs: "360px", md: "50%" },

          minHeight: "600px", // Set consistent height (optional, adjust as needed)
          p: 1, // Padding for better layout
          borderRadius: "10px",
          boxShadow: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start", // Align content to the top of the card
          mx: searchType === "staysOnly" ? "auto" : "unset", // Center only if staysOnly
        }}
      >
        <CardContent>
          {loading ? (
            <>
              <Skeleton
                variant="rectangular"
                width="90%"
                height={50}
                sx={{ mb: 2 }}
              />
              <Skeleton variant="text" width="60%" height={30} sx={{ mb: 1 }} />
              <Skeleton variant="text" width="80%" height={20} sx={{ mb: 1 }} />
              <Skeleton
                variant="rectangular"
                width="100%"
                height={200}
                sx={{ mb: 2 }}
              />
              <Skeleton variant="text" width="40%" height={40} sx={{ mb: 2 }} />
              <Skeleton variant="rectangular" width="60%" height={50} />
            </>
          ) : (
            <>
              {/* Header with Airbnb Logo and Text */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center", // Align logo and text vertically
                  justifyContent: "center", // Center the entire section horizontally
                  gap: 1.5, // Add space between logo and text
                  mb: 2, // Margin bottom for spacing
                  width: "100%", // Ensure it takes up full width
                }}
              >
                {/* Airbnb Logo */}
                <img
                  src="stay-icon.svg"
                  alt="stay Logo"
                  style={{
                    width: "90px",
                    height: "60px",
                    objectFit: "contain",
                  }}
                />

                {/* Header Text */}
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center", // Ensure the text is centered
                  }}
                >
                  Stays
                </Typography>
              </Box>

              {economyListings.length > 0 ||
              standardListings.length > 0 ||
              premiumListings.length > 0 ? (
                <>
                  {/* Toggle Buttons for Stay Categories */}
                  <ToggleButtonGroup
                    value={selectedStayCategory}
                    exclusive
                    onChange={(e, newValue) => {
                      if (newValue !== null) {
                        handleStayCategoryChange(e, newValue); // Call the handler from Home.js
                      }
                    }}
                    sx={{
                      mb: 0,
                      borderRadius: "8px",
                      backgroundColor: "#00000",
                      padding: "1px",
                    }}
                  >
                    {/* Economy Toggle Button */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        borderRadius: "8px", // Rounded corners
                      }}
                    >
                      <ToggleButton
                        value="economy"
                        key="economy"
                        disabled={economyListings.length === 0} // Disable if no results
                        sx={{
                          fontSize: "0.875rem",
                          padding: "6px 16px",
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          "&.Mui-selected": {
                            backgroundColor: "#FF5A5F", // Airbnb's color
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#FF5A5F", // Slightly darker for hover
                            },
                          },
                          "&:focus": {
                            backgroundColor: "#FF5A5F", // Ensure selected color stays on focus
                          },
                          "&:active": {
                            backgroundColor: "#FF5A5F", // Active state for touch feedback
                          },
                        }}
                      >
                        <Typography
                          variant="button"
                          sx={{ fontWeight: "bold", mb: "2px" }}
                        >
                          Economy
                        </Typography>
                        <Box
                          sx={{
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {economyListings.length > 0 ? (
                            <Typography
                              variant="caption"
                              sx={{ color: "grey.300" }}
                            >
                              $
                              {new Intl.NumberFormat("en-US").format(
                                Math.round(quartiles.Q1)
                              )}
                            </Typography>
                          ) : (
                            <Typography
                              variant="caption"
                              sx={{ visibility: "hidden" }}
                            >
                              $0
                            </Typography>
                          )}
                        </Box>
                      </ToggleButton>
                    </Box>

                    {/* Standard Toggle Button */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        borderRadius: "8px",
                      }}
                    >
                      <ToggleButton
                        value="standard"
                        key="standard"
                        disabled={standardListings.length === 0} // Disable if no results
                        sx={{
                          fontSize: "0.875rem",
                          padding: "6px 16px",
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          "&.Mui-selected": {
                            backgroundColor: "#FF5A5F",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#FF5A5F", // Slightly darker for hover
                            },
                          },
                          "&:focus": {
                            backgroundColor: "#FF5A5F", // Ensure selected color stays on focus
                          },
                          "&:active": {
                            backgroundColor: "#FF5A5F", // Active state for touch feedback
                          },
                        }}
                      >
                        <Typography
                          variant="button"
                          sx={{ fontWeight: "bold", mb: "2px" }}
                        >
                          Standard
                        </Typography>
                        <Box
                          sx={{
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {standardListings.length > 0 ? (
                            <Typography
                              variant="caption"
                              sx={{ color: "grey.300" }}
                            >
                              $
                              {new Intl.NumberFormat("en-US").format(
                                Math.round(quartiles.Q2)
                              )}
                            </Typography>
                          ) : (
                            <Typography
                              variant="caption"
                              sx={{ visibility: "hidden" }}
                            >
                              $0
                            </Typography>
                          )}
                        </Box>
                      </ToggleButton>
                    </Box>

                    {/* Premium Toggle Button */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        borderRadius: "8px",
                      }}
                    >
                      <ToggleButton
                        value="premium"
                        key="premium"
                        disabled={premiumListings.length === 0} // Disable if no results
                        sx={{
                          fontSize: "0.875rem",
                          padding: "6px 16px",
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          "&.Mui-selected": {
                            backgroundColor: "#FF5A5F",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#FF5A5F", // Slightly darker for hover
                            },
                          },
                          "&:focus": {
                            backgroundColor: "#FF5A5F", // Ensure selected color stays on focus
                          },
                          "&:active": {
                            backgroundColor: "#FF5A5F", // Active state for touch feedback
                          },
                        }}
                      >
                        <Typography
                          variant="button"
                          sx={{ fontWeight: "bold", mb: "2px" }}
                        >
                          Premium
                        </Typography>
                        <Box
                          sx={{
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {premiumListings.length > 0 ? (
                            <Typography
                              variant="caption"
                              sx={{ color: "grey.300" }}
                            >
                              $
                              {new Intl.NumberFormat("en-US").format(
                                Math.round(quartiles.Q3)
                              )}
                            </Typography>
                          ) : (
                            // Empty Typography to maintain space if no price is displayed
                            <Typography
                              variant="caption"
                              sx={{ visibility: "hidden" }}
                            >
                              $0
                            </Typography>
                          )}
                        </Box>
                      </ToggleButton>
                    </Box>
                  </ToggleButtonGroup>
                  {getFilterLabel(
                    airbnbType,
                    minReviewCount,
                    minReviewScore
                  ) !== "" && (
                    <Typography
                      variant="subtitle1"
                      sx={{
                        textAlign: "center",
                        color: "white",
                        mt: 0.5,
                        fontWeight: 300, // Adjust font weight (e.g., 300 for light, 500 for medium, 700 for bold)
                        fontSize: "0.9rem", // Adjust font size (use "px", "rem", etc.)
                      }}
                    >
                      {getFilterLabel(
                        airbnbType,
                        minReviewCount,
                        minReviewScore
                      )}
                    </Typography>
                  )}

                  {/* Display Selected Stay Listings */}
                  <Box
                    sx={{
                      textAlign: "center",
                      mt: 0,
                      display: "flex",
                      alignItems: "center", // Center items vertically
                      justifyContent: "center", // Center items horizontally
                      flexDirection: "column", // Stack items vertically
                      gap: "10px", // Add space between price and carousel
                    }}
                  >
                    <Box sx={{ mt: 2 }}>
                      {selectedStayCategory === "economy" && (
                        <ImageCarouselWithDetails
                          listings={economyListings}
                          departureDate={departureDate}
                          returnDate={returnDate}
                          totalStayPrice={Math.round(quartiles.Q1)} // Pass total price
                        />
                      )}
                      {selectedStayCategory === "standard" && (
                        <ImageCarouselWithDetails
                          listings={standardListings}
                          departureDate={departureDate}
                          returnDate={returnDate}
                          totalStayPrice={Math.round(quartiles.Q2)} // Pass total price
                        />
                      )}
                      {selectedStayCategory === "premium" && (
                        <ImageCarouselWithDetails
                          listings={premiumListings}
                          departureDate={departureDate}
                          returnDate={returnDate}
                          totalStayPrice={Math.round(quartiles.Q3)} // Pass total price
                        />
                      )}
                    </Box>
                  </Box>
                </>
              ) : !loading && noAirbnbFound ? (
                // Display "No Airbnb stays found." only after loading is done
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    minHeight: "600px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 2,
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    No Airbnb stays found at <br></br>{" "}
                    {submittedDestination?.city || "your destination"} on the
                    selected dates. <br></br> <br></br>Try different dates &
                    remove filters.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={handleBackToSearch}
                    sx={{
                      width: 150,
                      height: 50,
                      mt: 3,
                      backgroundColor: "#dc5253",
                      color: "white",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      borderRadius: "8px",
                      "&:hover": {
                        backgroundColor: (theme) => darken("#dc5253", 0.2), // Darken by 20%
                      },
                    }}
                  >
                    Edit Search
                  </Button>
                </Box>
              ) : (
                // Show the spinner if loading is true or final results not yet determined
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    minHeight: "600px",
                  }}
                >
                  <CircularProgress size={60} thickness={4} color="primary" />
                  <Typography
                    variant="h6"
                    sx={{
                      mt: 2,
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Loading stays...
                  </Typography>
                </Box>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Results;
