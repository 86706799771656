// CompactSearchForm.js
import React, { useState } from "react";
import {
  Box,
  TextField,
  Autocomplete,
  Button,
  InputAdornment,
} from "@mui/material";
import { FlightTakeoff, FlightLand } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { darken } from "@mui/system";

const CompactSearchForm = ({
  origin,
  setOrigin,
  originInput,
  setOriginInput,
  originOptions,
  destination,
  setDestination,
  destinationInput,
  setDestinationInput,
  destinationOptions,
  departureDate,
  setDepartureDate,
  returnDate,
  setReturnDate,
  handleSearch,
  flightType,
  handleBackToSearch,
  searchType,
}) => {
  const [errors, setErrors] = useState({});
  const [isDepartureOpen, setIsDepartureOpen] = useState(false);
  const [isReturnOpen, setIsReturnOpen] = useState(false);

  const validateFields = () => {
    const newErrors = {};

    if (searchType !== "staysOnly" && !origin) {
      newErrors.origin = "Origin is required";
    }

    if (!destination) newErrors.destination = "Destination is required";

    if (origin && destination && origin.city === destination.city) {
      newErrors.origin = "Origin and destination cannot be the same";
      newErrors.destination = "Origin and destination cannot be the same";
    }

    if (!departureDate) newErrors.departureDate = "Departure date is required";

    if (returnDate && returnDate < departureDate) {
      newErrors.returnDate = "Return date must be after the departure date";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleFormSubmit = () => {
    if (validateFields()) {
      handleSearch(searchType);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: { xs: "column", md: "row" }, // Column on xs and sm, row on md+
        gap: 0.5,
      }}
    >
      {/* Origin Autocomplete */}
      {searchType !== "staysOnly" && (
        <Box
          sx={{
            minWidth: { xs: "320px", md: "230px" },
            maxWidth: { xs: "320xpx", md: "230px" },
            position: "relative",
          }}
        >
          <Autocomplete
            options={originOptions}
            getOptionLabel={(option) => `${option.city}, ${option.country}`} // Display only city and country
            inputValue={originInput}
            onInputChange={(e, value) => setOriginInput(value)}
            value={origin}
            onChange={(e, value) => setOrigin(value)}
            filterOptions={(options, { inputValue }) =>
              options.filter(
                (option) =>
                  option.city
                    .toLowerCase()
                    .includes(inputValue.toLowerCase()) ||
                  option.country
                    .toLowerCase()
                    .includes(inputValue.toLowerCase()) ||
                  option.iata.toLowerCase() === inputValue.toLowerCase() // Allows IATA search
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Origin"
                error={!!errors.origin}
                helperText={errors.origin}
                FormHelperTextProps={{
                  sx: { position: "absolute", top: "100%" },
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FlightTakeoff />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>
      )}

      <Box
        sx={{
          minWidth:
            searchType === "staysOnly" ? "320px" : { xs: "320px", md: "230px" },
          maxWidth: { xs: "320xpx", md: "230px" },
          position: "relative",
          mt: { xs: 1, md: 0 },
        }}
      >
        <Autocomplete
          options={destinationOptions}
          getOptionLabel={(option) => `${option.city}, ${option.country}`} // Display only city and country
          inputValue={destinationInput}
          onInputChange={(e, value) => setDestinationInput(value)}
          value={destination}
          onChange={(e, value) => setDestination(value)}
          filterOptions={(options, { inputValue }) =>
            options.filter(
              (option) =>
                option.city.toLowerCase().includes(inputValue.toLowerCase()) ||
                option.country
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()) ||
                option.iata.toLowerCase() === inputValue.toLowerCase() // Allows IATA search
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Destination"
              error={!!errors.destination}
              helperText={errors.destination}
              FormHelperTextProps={{
                sx: { position: "absolute", top: "100%" },
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <FlightLand />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Box>

      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row", // Default to column for xs and sm screens
          justifyContent: "center", // Center items in the parent box
          gap: 1, // Space between items
          alignItems: "center", // Align items vertically
          mt: { xs: 0, md: 0 },

          [theme.breakpoints.up("md")]: {
            flexDirection: "row", // Switch to row for md and larger screens
            justifyContent: "center", // Center items horizontally on larger screens
          },
        })}
      >
        {/* Departure Date Picker */}
        <Box
          sx={{
            minWidth: { xs: "155px", md: "150px" },
            maxWidth: { xs: "155px", md: "150px" },
            position: "relative",
            mt: { xs: 1, md: 0 },
          }}
        >
          <DatePicker
            label="Departure Date"
            value={departureDate}
            onChange={(newValue) => {
              setDepartureDate(newValue);
              // Reset return date if it's before the new departure date
              if (returnDate && newValue && returnDate < newValue) {
                setReturnDate(null);
                setErrors((prev) => ({
                  ...prev,
                  returnDate: "Return date must be after the departure date",
                }));
              } else {
                setErrors((prev) => ({
                  ...prev,
                  returnDate: undefined,
                }));
              }
            }}
            open={isDepartureOpen}
            onOpen={() => setIsDepartureOpen(true)}
            onClose={() => setIsDepartureOpen(false)}
            // **Added minDate to prevent selecting past dates**
            minDate={new Date()}
            slotProps={{
              textField: {
                error: !!errors.departureDate,
                helperText: errors.departureDate,
                FormHelperTextProps: {
                  sx: { position: "absolute", top: "100%" },
                },
                inputProps: {
                  readOnly: true,
                  onClick: () => setIsDepartureOpen(true),
                },
              },
            }}
          />
        </Box>

        {/* Return Date Picker */}
        <Box
          sx={{
            minWidth: { xs: "155px", md: "150px" },
            maxWidth: { xs: "155px", md: "150px" },
            position: "relative",
            mt: { xs: 1, md: 0 },
          }}
        >
          {" "}
          <DatePicker
            label={flightType === "oneway" ? "Checkout Date" : "Return Date"} // Updated label based on flightType
            value={returnDate}
            onChange={(newValue) => setReturnDate(newValue)}
            open={isReturnOpen}
            onOpen={() => setIsReturnOpen(true)}
            onClose={() => setIsReturnOpen(false)}
            // **Added minDate to ensure returnDate is not before departureDate**
            minDate={
              departureDate
                ? new Date(departureDate.getTime() + 24 * 60 * 60 * 1000)
                : new Date()
            }
            slotProps={{
              textField: {
                error: !!errors.returnDate,
                helperText: errors.returnDate,
                FormHelperTextProps: {
                  sx: { position: "absolute", top: "100%" },
                },
                inputProps: {
                  readOnly: true,
                  onClick: () => setIsReturnOpen(true),
                },
              },
            }}
          />
        </Box>
      </Box>

      {/* Search Button */}
      <Button
        variant="contained"
        onClick={handleFormSubmit}
        sx={{
          width: { xs: "200px", md: "100px" },
          height: 60,
          mt: { xs: 1, md: 0 },
          ml: { xs: 0, md: 0.5 },
          backgroundColor: "primary.main",
          color: "white",
          fontSize: { xs: "1rem", md: "0.9  5rem" },
          fontWeight: "bold",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: (theme) => darken(theme.palette.primary.main, 0.2), // Darken by 20%
          },
        }}
      >
        Search
      </Button>
    </Box>
  );
};

export default CompactSearchForm;
