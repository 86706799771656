// App.js
import React from "react";
import { Routes, Route } from "react-router-dom"; // Ensure React Router v6 is used
import Layout from "./components/Layout";
import Home from "./components/Home";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react"; // For Vercel Speed Insights
import Maintenance from "./components/Maintenance"; // Import Maintenance component
import ContactPage from "./components/Contact";
import About from "./components/About"
import * as Sentry from "@sentry/react"; // Import Sentry SDK
import { browserTracingIntegration, replayIntegration } from "@sentry/react";

// Initialize Sentry
Sentry.init({
  dsn: "https://e847e56bf75d2153389dae383d232ba9@o4508492184354816.ingest.us.sentry.io/4508492186517504",
  integrations: [
    browserTracingIntegration({
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

function App() {
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === "true";

  return (
    <>
      {isMaintenanceMode ? (
        <Maintenance /> // Show Maintenance page if the flag is true
      ) : (
        <>
          <Routes>
            {/* Parent Route with Layout */}
            <Route path="/" element={<Layout />}>
              {/* Nested Routes */}
              <Route index element={<Home />} /> {/* Renders at "/" */}
              <Route path="contact" element={<ContactPage />} />
              <Route path="about" element={<About />} /> {/* Add this line */}
              {/* Add more nested routes here if needed */}
            </Route>
          </Routes>
          <SpeedInsights />
          <Analytics />
        </>
      )}
    </>
  );
}

export default Sentry.withProfiler(App); // Wrap App with Sentry Profiler
