// src/components/Home.js
import React, { useState, useMemo, useEffect, useCallback } from "react";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SearchForm from "./SearchForm"; // Adjust path as needed
import CompactSearchForm from "./CompactSearchForm";
import Results from "./Results";
import { Container, Typography, Box, CircularProgress } from "@mui/material";
import { LocalizationProvider, PickersDay } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDebounce } from "use-debounce";
import rawAirportsData from "../airports.json"; // Adjust path as needed
import { styled } from "@mui/material/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import HowItWorks from "./HowItWorks";
import Explainer from "./Explainer";
import ContactForm from "./ContactForm";
import Subscribe from "./Subscribe";
import { Divider, Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { darken } from "@mui/system";
import { supabase } from "../utils/supabaseClient";
import { logger } from "../utils/logger";
import { Helmet } from "react-helmet"; // Import react-helmet

const CustomPickersDayStyled = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "departureDate" && prop !== "key", // Exede 'key'
})(({ theme, departureDate, day }) => ({
  ...(departureDate &&
    day.getTime() === departureDate.getTime() && {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    }),
  "&.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
}));

const Home = () => {
  // State variables
  const [originInput, setOriginInput] = useState("");
  const [destinationInput, setDestinationInput] = useState("");
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [departureDate, setDepartureDate] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0); // Add children state
  const [flightResults, setFlightResults] = useState([]); // Default to empty array
  const [noFlightsFound, setNoFlightsFound] = useState(false); // New state
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("best");
  const [allItineraries, setAllItineraries] = useState([]);
  const [searchInitiated, setSearchInitiated] = useState(false); // Add this new state
  const [selectedStayCategory, setSelectedStayCategory] = useState("standard"); // Default to 'standard'
  const [economyListings, setEconomyListings] = useState([]);
  const [standardListings, setStandardListings] = useState([]);
  const [premiumListings, setPremiumListings] = useState([]);
  const [flightType, setFlightType] = useState("roundtrip");
  const [airbnbType, setAirbnbType] = useState("both"); // Default value is 'both'
  const [submittedOrigin, setSubmittedOrigin] = useState(null);
  const [submittedDestination, setSubmittedDestination] = useState(null);
  const [submittedDepartureDate, setSubmittedDepartureDate] = useState(null);
  const [submittedReturnDate, setSubmittedReturnDate] = useState(null);
  const [noAirbnbFound, setNoAirbnbFound] = useState(false);
  const location = useLocation();
  const [searchType, setSearchType] = useState("flightsAndStays");
  const [minReviewCount, setMinReviewCount] = useState(5); // Default to 5
  const [minReviewScore, setMinReviewScore] = useState(4.0); // Default to 4.00 as a number

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get("scrollTo");

    if (scrollTo === "contact") {
      const contactSection = document.querySelector("#contact");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }

      // Clear the query parameter from URL without reloading the page
      const newUrl = window.location.pathname;
      window.history.replaceState(null, "", newUrl);
    }
  }, [location.search]);

  // Handle stay category change
  const handleStayCategoryChange = (event, newValue) => {
    if (newValue !== null) {
      setSelectedStayCategory(newValue);
    }
  };

  // Format date for SkyScanner URL
  const formatDateForSkyScanner = (date) => {
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}${month}${day}`;
  };

  // Generate SkyScanner link
  const generateSkyScannerLink = useCallback(() => {
    if (!origin || !destination || !departureDate) return "#";

    const formattedDepartureDate = formatDateForSkyScanner(departureDate);
    const originIATA = origin.iata;
    const destinationIATA = destination.iata;

    let skyscannerUrl = `https://www.skyscanner.com/transport/flights/${originIATA}/${destinationIATA}/${formattedDepartureDate}`;

    // Add return date only if it's a roundtrip
    if (flightType === "roundtrip" && returnDate) {
      const formattedReturnDate = formatDateForSkyScanner(returnDate);
      skyscannerUrl += `/${formattedReturnDate}`;
    }
    // Create query parameters
    const queryParams = new URLSearchParams({
      adults: adults.toString(), // SkyScanner sometimes needs this
      adultsv2: adults.toString(), // Required for correct search
      cabinclass: "economy",
      infants: "0", // No infants by default
      preferdirects: "false",
      outboundaltsenabled: "true",
      inboundaltsenabled: "true",
      ref: "home",
      rtn: flightType === "roundtrip" ? "1" : "0",
    });

    // Only add children if children > 0
    if (children > 0) {
      const childrenAges = Array(children).fill("4").join("|"); // Use "|" as separator
      queryParams.append("children", children.toString());
      queryParams.append("childrenv2", childrenAges);
    }

    // Return final URL
    return `${skyscannerUrl}/?${queryParams.toString()}`;
  }, [
    origin,
    destination,
    departureDate,
    returnDate,
    adults,
    children,
    flightType,
  ]);

  // Debounce the input values to improve performance
  const [debouncedOriginInput] = useDebounce(originInput, 300);
  const [debouncedDestinationInput] = useDebounce(destinationInput, 300);

  // Filter and sort airports data
  const airportsData = useMemo(() => {
    return rawAirportsData.filter(
      (airport) =>
        airport.iata &&
        airport.iata.length === 3 &&
        airport.iata !== "\\N" &&
        airport.type !== "heliport" &&
        airport.type !== "closed" &&
        airport.type !== "seaplane_base" &&
        airport.type !== "balloonport"
    );
  }, []);

  const filterAirports = useCallback(
    (inputValue) => {
      if (!inputValue) return [];

      const searchStr = inputValue.toLowerCase();

      let exactIATAMatches = [];
      let iataStartsWithMatches = [];
      let cityMatches = [];

      // Exact IATA code match (only when input is exactly 3 characters)
      if (inputValue.length === 3) {
        exactIATAMatches = airportsData
          .filter((airport) => airport.iata?.toLowerCase() === searchStr)
          .map((airport) => ({
            city: airport.city,
            state:
              airport.country === "United States" ||
              airport.country === "Canada"
                ? airport.state || ""
                : "",
            country: airport.country,
            iata: airport.iata,
          }));
      }

      // Starts-with IATA code matches (only for inputs of length 1-2)
      if (inputValue.length < 3) {
        iataStartsWithMatches = airportsData
          .filter((airport) =>
            airport.iata?.toLowerCase().startsWith(searchStr)
          )
          .map((airport) => ({
            city: airport.city,
            state:
              airport.country === "United States" ||
              airport.country === "Canada"
                ? airport.state || ""
                : "",
            country: airport.country,
            iata: airport.iata,
          }));
      }

      // City name matches
      if (inputValue.length > 3) {
        // For input longer than 3 characters, match city names that **start with** the search string
        cityMatches = airportsData
          .filter((airport) =>
            airport.city?.toLowerCase().startsWith(searchStr)
          )
          .map((airport) => ({
            city: airport.city,
            state:
              airport.country === "United States" ||
              airport.country === "Canada"
                ? airport.state || ""
                : "",
            country: airport.country,
            iata: airport.iata,
          }));
      } else {
        // For input of 3 or fewer characters, match city or country names that **include** the search string
        cityMatches = airportsData
          .filter(
            (airport) =>
              airport.city?.toLowerCase().includes(searchStr) ||
              airport.country?.toLowerCase().includes(searchStr)
          )
          .map((airport) => ({
            city: airport.city,
            state:
              airport.country === "United States" ||
              airport.country === "Canada"
                ? airport.state || ""
                : "",
            country: airport.country,
            iata: airport.iata,
          }));
      }

      // Combine all matches
      const allMatches = [
        ...exactIATAMatches,
        ...iataStartsWithMatches,
        ...cityMatches,
      ];

      // Remove duplicates per city by using city and country as the key
      const uniqueMatches = Object.values(
        allMatches.reduce((acc, curr) => {
          const key = `${curr.city}-${curr.country}`;
          if (!acc[key]) {
            acc[key] = curr;
          }
          return acc;
        }, {})
      );

      // Sorting logic
      const sortedMatches = uniqueMatches.sort((a, b) => {
        const aCity = a.city.toLowerCase();
        const bCity = b.city.toLowerCase();

        const aStartsWithSearch = aCity.startsWith(searchStr);
        const bStartsWithSearch = bCity.startsWith(searchStr);

        // Prioritize cities that start with the search string
        if (aStartsWithSearch && !bStartsWithSearch) return -1;
        if (!aStartsWithSearch && bStartsWithSearch) return 1;

        // Sort alphabetically by city name
        const cityComparison = aCity.localeCompare(bCity);
        if (cityComparison !== 0) return cityComparison;

        // If city names are the same, sort alphabetically by country
        return a.country.localeCompare(b.country);
      });

      return sortedMatches;
    },
    [airportsData]
  );

  // Filtered options for Origin Autocomplete
  const originOptions = useMemo(
    () => filterAirports(debouncedOriginInput),
    [debouncedOriginInput, filterAirports]
  );

  // Filtered options for Destination Autocomplete
  const destinationOptions = useMemo(
    () => filterAirports(debouncedDestinationInput),
    [debouncedDestinationInput, filterAirports]
  );

  // Cache entity IDs in localStorage with key `entityIdsCache`
  const loadEntityIdsCache = () =>
    JSON.parse(localStorage.getItem("entityIdsCache")) || {};
  const saveEntityIdsCache = (cache) =>
    localStorage.setItem("entityIdsCache", JSON.stringify(cache));

  const getEntityId = useCallback(async (airports) => {
    const entityIdsCache = loadEntityIdsCache(); // Load from localStorage

    for (const airport of airports) {
      const { iata } = airport;

      // Check localStorage first
      if (entityIdsCache[iata]) {
        logger.log(
          `Entity ID for ${iata} found in localStorage: ${entityIdsCache[iata]}`
        );
        return entityIdsCache[iata];
      }

      // Check Supabase for existing row with entity_id
      let { data: supabaseData, error: supabaseError } = await supabase
        .from("airports")
        .select("entity_id")
        .eq("iata", iata)
        .single();

      if (supabaseError && supabaseError.code !== "PGRST116") {
        // Log error unless it's the "No rows found" case
        logger.error(
          `Error fetching from Supabase for ${iata}:`,
          supabaseError
        );
      }

      if (supabaseData?.entity_id) {
        logger.log(
          `Entity ID for ${iata} found in Supabase: ${supabaseData.entity_id}`
        );

        // Cache in localStorage for faster future access
        entityIdsCache[iata] = supabaseData.entity_id;
        saveEntityIdsCache(entityIdsCache);

        return supabaseData.entity_id;
      }

      // If no entity_id in Supabase, fetch from API
      logger.log(`Entity ID for ${iata} not found. Fetching from API...`);

      const options = {
        method: "GET",
        url: "https://sky-scrapper.p.rapidapi.com/api/v1/flights/searchAirport",
        params: { query: iata, locale: "en-US" },
        headers: {
          "x-rapidapi-key": process.env.REACT_APP_RAPIDAPI_KEY,
          "x-rapidapi-host": process.env.REACT_APP_RAPIDAPI_HOST,
        },
      };

      try {
        const response = await axios.request(options);
        const entityId = response.data?.data?.[0]?.entityId || null;

        if (entityId) {
          logger.log(`Fetched Entity ID for ${iata} from API: ${entityId}`);

          // Cache in localStorage
          entityIdsCache[iata] = entityId;
          saveEntityIdsCache(entityIdsCache);

          // Upsert into Supabase (insert if new, update if exists)
          const { error: upsertError } = await supabase.from("airports").upsert(
            { iata, entity_id: entityId },
            { onConflict: "iata" } // Ensure it targets the correct row
          );

          if (upsertError) {
            logger.error(
              `Error inserting/updating entity ID for ${iata} into Supabase:`,
              upsertError
            );
          }

          return entityId;
        } else {
          logger.error(`No entity ID found in API response for ${iata}.`);
        }
      } catch (error) {
        logger.error(`Error fetching entity ID for ${iata}:`, error);
      }
    }

    logger.error("No valid entity ID found for any airport.");
    return null;
  }, []);

  const handleClearStayFilter = () => {
    setAirbnbType("both");
  };

  const formatLocalDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Function to fetch Airbnb stays, memoized with useCallback
  const fetchAirbnbStays = useCallback(
    async (destination) => {
      const location = `${destination.city}, ${destination.country}`;
      const checkinDate = formatLocalDate(departureDate);
      const checkoutDate = formatLocalDate(returnDate);
      const pageCount =
        (minReviewCount >= 30 || minReviewScore >= 4.7) &&
        (airbnbType === "entire_home" || airbnbType === "private_room")
          ? 3
          : minReviewCount >= 30 ||
              minReviewScore >= 4.7 ||
              airbnbType !== "both"
            ? 2
            : 1;

      const fetchPage = async (page) => {
        const options = {
          method: "GET",
          url: "https://airbnb13.p.rapidapi.com/search-location",
          params: {
            location,
            checkin: checkinDate,
            checkout: checkoutDate,
            adults: String(adults),
            children: String(children),
            infants: "0",
            pets: "0",
            page: String(page),
            currency: "USD",
          },
          headers: {
            "x-rapidapi-key": process.env.REACT_APP_RAPIDAPI_KEY,
            "x-rapidapi-host": "airbnb13.p.rapidapi.com",
          },
        };

        try {
          const response = await axios.request(options);
          logger.log(`Airbnb Response for page ${page}:`, response.data);
          return response.data.results || []; // Return the results directly
        } catch (error) {
          logger.error(`Error fetching Airbnb stays for page ${page}:`, error);
          return []; // Return empty array if there's an error
        }
      };

      // Fetch results from the specified number of pages
      const promises = Array.from({ length: pageCount }, (_, index) =>
        fetchPage(index + 1)
      );
      const allResults = (await Promise.all(promises)).flat();

      return allResults;
    },
    [
      departureDate,
      returnDate,
      adults,
      children,
      airbnbType,
      minReviewCount,
      minReviewScore,
    ]
  );

  const normalizeListingPrices = useCallback((listings, nights) => {
    return listings.map((listing) => {
      if (listing.price.total === null && listing.price.rate) {
        const estimatedNightlyRate = listing.price.rate / 30;
        listing.price.total = estimatedNightlyRate * nights;
      }
      return listing;
    });
  }, []);

  // Calculate Percentile Groups and their Medians
  const calculateQuartiles = useCallback((numbers) => {
    const validNumbers = numbers.filter((price) => price !== null); // Filter out null prices
    if (validNumbers.length === 0) return { Q1: null, Q2: null, Q3: null };

    const sorted = numbers.slice().sort((a, b) => a - b);

    const getMedian = (array) => {
      const mid = Math.floor(array.length / 2);
      return array.length % 2 !== 0
        ? array[mid]
        : (array[mid - 1] + array[mid]) / 2;
    };

    const size = Math.ceil(sorted.length / 3); // Size of each group

    // Split the sorted numbers into three groups
    const Q1Group = sorted.slice(0, size); // 0-33%
    const Q2Group = sorted.slice(size, size * 2); // 34-66%
    const Q3Group = sorted.slice(size * 2); // 67-100%

    return {
      Q1: getMedian(Q1Group),
      Q2: getMedian(Q2Group),
      Q3: getMedian(Q3Group),
      Q1Group,
      Q2Group,
      Q3Group,
    };
  }, []);

  // Classify Listings Based on Groups
  const classifyAndSortListingsByPrice = useCallback((listings, quartiles) => {
    if (listings.length < 6) {
      // If less than 6 listings, assign all to "standard" category
      return {
        economyListings: [],
        standardListings: listings,
        premiumListings: [],
      };
    }
    const { Q1Group, Q2Group, Q3Group } = quartiles;

    const sortByReviews = (a, b) => b.reviewsCount - a.reviewsCount;

    const economyListings = listings
      .filter((listing) => Q1Group.includes(listing.price.total))
      .sort(sortByReviews);

    const standardListings = listings
      .filter((listing) => Q2Group.includes(listing.price.total))
      .sort(sortByReviews);

    const premiumListings = listings
      .filter((listing) => Q3Group.includes(listing.price.total))
      .sort(sortByReviews);

    return { economyListings, standardListings, premiumListings };
  }, []);

  // Quartiles state
  const [quartiles, setQuartiles] = useState({ Q1: null, Q2: null, Q3: null });

  // Memoized selection functions

  const selectBestItinerary = useCallback(
    (allItineraries, fastestItinerary) => {
      if (!Array.isArray(allItineraries) || allItineraries.length === 0)
        return null;

      const initialBest = allItineraries.reduce(
        (best, current) =>
          current.score > best.score ||
          (current.score === best.score && current.price.raw < best.price.raw)
            ? current
            : best,
        allItineraries[0]
      );

      // Check if fastestItinerary is cheaper and return it as best if true
      if (
        fastestItinerary &&
        fastestItinerary.price.raw < initialBest.price.raw
      ) {
        return fastestItinerary;
      }

      return initialBest;
    },
    []
  );

  const selectCheapestItinerary = useCallback((allItineraries) => {
    if (allItineraries.length === 0) return null;

    const selected = allItineraries.reduce(
      (cheapest, current) =>
        current.price.raw < cheapest.price.raw ? current : cheapest,
      allItineraries[0]
    );
    return selected;
  }, []);

  const selectFastestItinerary = useCallback((allItineraries) => {
    const taggedItineraries = allItineraries.filter((it) =>
      it.tags?.includes("shortest")
    );

    if (taggedItineraries.length === 0 && allItineraries.length > 0) {
      logger.warn("No itineraries with the 'shortest' tag were found.");
    }

    const selected =
      taggedItineraries.length > 0
        ? taggedItineraries.reduce(
            (cheapest, current) =>
              current.price.raw < cheapest.price.raw ? current : cheapest,
            taggedItineraries[0]
          )
        : allItineraries.reduce(
            (fastest, current) =>
              current.durationInMinutes < fastest.durationInMinutes
                ? current
                : fastest,
            allItineraries[0]
          );

    return selected;
  }, []);
  const fastestItinerary = useMemo(
    () =>
      allItineraries && allItineraries.length > 0
        ? selectFastestItinerary(allItineraries)
        : null,
    [selectFastestItinerary, allItineraries]
  );

  // Define the itineraries based on the current state
  const bestItinerary = useMemo(
    () =>
      allItineraries && allItineraries.length > 0
        ? selectBestItinerary(allItineraries, fastestItinerary)
        : null,
    [selectBestItinerary, allItineraries, fastestItinerary]
  );

  const cheapestItinerary = useMemo(
    () =>
      allItineraries && allItineraries.length > 0
        ? selectCheapestItinerary(allItineraries)
        : null,
    [selectCheapestItinerary, allItineraries]
  );

  // Handle sort change
  const handleSortChange = useCallback(
    (newSortBy) => {
      setSortBy(newSortBy);

      let selectedItinerary;
      if (newSortBy === "cheap") {
        selectedItinerary = selectCheapestItinerary(allItineraries);
      } else if (newSortBy === "fastest") {
        selectedItinerary = selectFastestItinerary(allItineraries);
      } else {
        selectedItinerary = selectBestItinerary(allItineraries); // Pass the whole array
      }

      setFlightResults(selectedItinerary ? [selectedItinerary] : []);
    },
    [
      selectBestItinerary,
      selectCheapestItinerary,
      selectFastestItinerary,
      allItineraries,
    ]
  );

  const handleStaySearch = useCallback(async () => {
    if (!destination || !departureDate || !returnDate) {
      logger.error("Please provide a destination and valid dates for stays.");
      return;
    }

    logger.time("Stays Search Duration");
    setLoading(true);
    setSearchInitiated(true); // Initiate search
    setNoAirbnbFound(false); // Reset noAirbnbFound state
    setSubmittedDestination(destination);
    setSubmittedDepartureDate(departureDate);
    setSubmittedReturnDate(returnDate);

    try {
      // Fetch Airbnb stays based on the destination
      const airbnbResults = await fetchAirbnbStays(destination);

      if (airbnbResults.length === 0) {
        logger.warn("No Airbnb stays found.");
        setNoAirbnbFound(true);
      } else {
        // Process valid Airbnb results
        const nights = Math.ceil(
          (returnDate - departureDate) / (1000 * 60 * 60 * 24)
        );

        // Normalize prices and filter valid results
        const validAirbnbResults = normalizeListingPrices(
          airbnbResults.filter((result) => {
            const typeLowerCase = result.type.toLowerCase();
            const matchesType =
              airbnbType === "both" ||
              (airbnbType === "entire_home" &&
                (typeLowerCase.includes("entire") ||
                  typeLowerCase.includes("apartment") ||
                  typeLowerCase.includes("house"))) ||
              (airbnbType === "private_room" &&
                typeLowerCase.includes("private") &&
                typeLowerCase.includes("room"));

            const meetsReviewCount = result.reviewsCount >= minReviewCount;
            const meetsReviewScore = result.rating >= minReviewScore;

            return meetsReviewCount && meetsReviewScore && matchesType;
          }),
          nights
        );

        // Handle no valid Airbnb results
        if (validAirbnbResults.length === 0) {
          logger.warn("No matching Airbnb listings found.");
          setNoAirbnbFound(true);
        } else {
          // Set the state for valid Airbnb listings
          setNoAirbnbFound(false);
          setSearchInitiated(true); // This ensures results will display

          // Quartile calculation for Airbnb prices
          let calculatedQuartiles;
          if (validAirbnbResults.length === 1) {
            const price = validAirbnbResults[0].price.total;
            calculatedQuartiles = { Q1: price, Q2: price, Q3: price };
          } else if (validAirbnbResults.length === 2) {
            calculatedQuartiles = {
              Q1: validAirbnbResults[0].price.total,
              Q2:
                (validAirbnbResults[0].price.total +
                  validAirbnbResults[1].price.total) /
                2,
              Q3: validAirbnbResults[1].price.total,
            };
          } else {
            const totalPrices = validAirbnbResults.map(
              (result) => result.price.total
            );
            calculatedQuartiles = calculateQuartiles(totalPrices);
          }

          // Set calculated quartiles in the state
          setQuartiles(calculatedQuartiles);

          const { economyListings, standardListings, premiumListings } =
            classifyAndSortListingsByPrice(
              validAirbnbResults,
              calculatedQuartiles
            );

          // Set the listings in their respective categories
          setEconomyListings(economyListings);
          setStandardListings(standardListings);
          setPremiumListings(premiumListings);
        }
      }
    } catch (error) {
      logger.error("Error fetching stays:", error);
    } finally {
      setLoading(false);
      logger.timeEnd("Stays Search Duration");
    }
  }, [
    destination,
    departureDate,
    returnDate,
    fetchAirbnbStays,
    airbnbType,
    normalizeListingPrices,
    calculateQuartiles,
    classifyAndSortListingsByPrice,
    minReviewCount,
    minReviewScore,
  ]);

  const logSearchData = useCallback(async () => {
    const { error } = await supabase.from("search_logs").insert([
      {
        origin: origin?.city || null,
        destination: destination?.city || null,
        departure_date: departureDate || null,
        return_date: returnDate || null,
        search_type: searchType,
        flight_type: flightType,
        adults: adults,
        children: children,
        stay_type: airbnbType,
        min_review_score: minReviewScore,
        min_review_count: minReviewCount,
      },
    ]);

    if (error) {
      console.error("Error logging search data:", error.message);
    } else {
      console.log("Search data logged successfully");
    }
  }, [
    origin?.city,
    destination?.city,
    departureDate,
    returnDate,
    searchType,
    flightType,
    adults,
    children,
    airbnbType,
    minReviewScore,
    minReviewCount,
  ]);

  // Handling the Search
  const handleSearch = useCallback(
    async (currentSearchType) => {
      // Reset state before search
      setLoading(true);
      setSearchInitiated(true);

      // Log search data before executing the search
      await logSearchData();

      setNoFlightsFound(false);
      setNoAirbnbFound(false);
      setFlightResults([]);
      setAllItineraries([]);
      setEconomyListings([]);
      setStandardListings([]);
      setPremiumListings([]);
      if (currentSearchType === "staysOnly") {
        await handleStaySearch();
        return;
      }

      // Proceed with the existing flight and stay search logic
      if (!origin || !destination || !departureDate || !returnDate) {
        logger.error(
          "Please provide all required fields for flights and stays."
        );
        return;
      }

      logger.time("Search Duration"); // Start timer
      const startTime = performance.now(); // Alternative timer for duration

      const formattedDepartureDate = departureDate.toLocaleDateString("en-CA");
      const formattedReturnDate = returnDate
        ? returnDate.toLocaleDateString("en-CA")
        : undefined;

      setLoading(true);
      setSearchInitiated(true);
      setNoFlightsFound(false);
      setFlightResults([]); // Clear previous flight results
      setAllItineraries([]); // Clear previous itineraries
      setEconomyListings([]); // Clear previous Airbnb economy listings
      setStandardListings([]); // Clear previous Airbnb standard listings
      setPremiumListings([]); // Clear previous Airbnb premium listings
      setSubmittedOrigin(origin);
      setSubmittedDestination(destination);
      setSubmittedDepartureDate(departureDate);
      setSubmittedReturnDate(returnDate);

      setTimeout(() => {
        setLoading(false); // Switch to skeleton after 2 seconds
      }, 3500);

      try {
        const [originEntityId, destinationEntityId] = await Promise.all([
          getEntityId(
            airportsData.filter(
              (airport) =>
                airport.city === origin.city &&
                airport.country === origin.country
            )
          ),
          getEntityId(
            airportsData.filter(
              (airport) =>
                airport.city === destination.city &&
                airport.country === destination.country
            )
          ),
        ]);

        if (!originEntityId || !destinationEntityId) {
          logger.error("Unable to fetch entity IDs.");
          setNoFlightsFound(true);
          await handleStaySearch(); // Continue with Airbnb search
          setLoading(false);
          return; // Exit to avoid further flight processing
        }

        const searchOptions = {
          method: "GET",
          url: "https://sky-scrapper.p.rapidapi.com/api/v2/flights/searchFlights",
          //url: "https://sky-scrapper.p.rapidapi.com/api/v2/flights/searchFlightsWebComplete",
          params: {
            originSkyId: origin.iata,
            destinationSkyId: destination.iata,
            originEntityId,
            destinationEntityId,
            date: formattedDepartureDate,
            ...(flightType === "roundtrip" && {
              returnDate: formattedReturnDate,
            }),
            adults: String(adults),
            children: String(children),
            sortBy: "best",
            limit: 1000,
            currency: "USD",
            market: "en-US",
            countryCode: "US",
          },
          headers: {
            "x-rapidapi-key": process.env.REACT_APP_RAPIDAPI_KEY,
            "x-rapidapi-host": process.env.REACT_APP_RAPIDAPI_HOST,
          },
        };

        const [firstResponse, airbnbResults] = await Promise.all([
          axios.request(searchOptions).catch((error) => {
            handleFlightError(error);
          }),
          fetchAirbnbStays(destination),
        ]);

        const firstItineraries = Array.isArray(
          firstResponse?.data?.data?.itineraries
        )
          ? firstResponse.data.data.itineraries
          : [];
        logger.log("First Itineraries:", firstItineraries);

        // Check if both flights and Airbnb are empty
        if (firstItineraries.length === 0 && airbnbResults.length === 0) {
          // No flights and no stays found
          logger.warn(
            "No flights and no Airbnb stays found at all. Exiting early."
          );

          setNoFlightsFound(true);
          setNoAirbnbFound(true);
          setLoading(false);
          return; // Exit the handleSearch function early
        }

        // Stop search early if no flights are found from the first response
        if (firstItineraries.length === 0) {
          logger.warn(
            "No flights found in the first API response. Stopping search."
          );
          setNoFlightsFound(true);
          setLoading(false);
          return; // Exit early
        }
        // ALWAYS do second and third calls now:
        const secondPromise = new Promise((resolve) =>
          setTimeout(resolve, 1000)
        )
          .then(() => axios.request(searchOptions))
          .catch((error) => {
            logger.error("Error with second API call:", error);
            return { data: { data: { itineraries: [] } } };
          });

        const thirdPromise = new Promise((resolve) => setTimeout(resolve, 2000))
          .then(() => axios.request(searchOptions))
          .catch((error) => {
            logger.error("Error with third API call:", error);
            return { data: { data: { itineraries: [] } } };
          });

        // Wait for second and third in parallel
        const [secondResponse, thirdResponse] = await Promise.all([
          secondPromise,
          thirdPromise,
        ]);

        const secondItineraries = Array.isArray(
          secondResponse?.data?.data?.itineraries
        )
          ? secondResponse.data.data.itineraries
          : [];
        logger.log("Second Itineraries:", secondItineraries);

        const thirdItineraries = Array.isArray(
          thirdResponse?.data?.data?.itineraries
        )
          ? thirdResponse.data.data.itineraries
          : [];
        logger.log("Third Itineraries:", thirdItineraries);

        const combinedItineraries = [
          ...firstItineraries,
          ...secondItineraries,
          ...thirdItineraries,
        ];

        if (combinedItineraries.length === 0) {
          setNoFlightsFound(true);
        }

        setAllItineraries(
          Array.isArray(combinedItineraries) ? combinedItineraries : []
        );

        const selectedItinerary =
          Array.isArray(combinedItineraries) && combinedItineraries.length > 0
            ? sortBy === "cheap"
              ? selectCheapestItinerary(combinedItineraries)
              : sortBy === "fastest"
                ? selectFastestItinerary(combinedItineraries)
                : selectBestItinerary(combinedItineraries) // Apply best itinerary logic here
            : null;

        setFlightResults(selectedItinerary ? [selectedItinerary] : []);

        const nights = Math.ceil(
          (returnDate - departureDate) / (1000 * 60 * 60 * 24)
        );

        const validAirbnbResults = normalizeListingPrices(
          airbnbResults.filter((result) => {
            const typeLowerCase = result.type.toLowerCase();

            const matchesType =
              airbnbType === "both" ||
              (airbnbType === "entire_home" &&
                (typeLowerCase.includes("entire") ||
                  typeLowerCase.includes("apartment") || // Sometimes categorized as apartments
                  typeLowerCase.includes("house"))) ||
              (airbnbType === "private_room" &&
                typeLowerCase.includes("private") &&
                typeLowerCase.includes("room"));

            return result.reviewsCount >= 5 && matchesType;
          }),
          nights
        );

        // Check for no Airbnb results
        if (validAirbnbResults.length === 0) {
          logger.warn("No matching Airbnb listings found.");
          setNoAirbnbFound(true);
          // Don't return JSX here. Just set the state.
        } else {
          // Proceed with processing listings into quartiles and categories...
          setNoAirbnbFound(false);
        }

        // Handle cases with one or two results
        let calculatedQuartiles;
        if (validAirbnbResults.length === 1) {
          // Only one result; set the same price for all quartiles
          const price = validAirbnbResults[0].price.total;
          calculatedQuartiles = { Q1: price, Q2: price, Q3: price };
        } else if (validAirbnbResults.length === 2) {
          // Two results; use the first and second prices directly
          calculatedQuartiles = {
            Q1: validAirbnbResults[0].price.total,
            Q2:
              (validAirbnbResults[0].price.total +
                validAirbnbResults[1].price.total) /
              2,
            Q3: validAirbnbResults[1].price.total,
          };
        } else {
          // Normal quartile calculation for three or more results
          const totalPrices = validAirbnbResults.map(
            (result) => result.price.total
          );
          calculatedQuartiles = calculateQuartiles(totalPrices);
        }

        // Set the calculated quartiles
        setQuartiles(calculatedQuartiles);

        const totalPrices = validAirbnbResults.map(
          (result) => result.price.total
        );
        const quartiles = calculateQuartiles(totalPrices);

        setQuartiles(quartiles);

        const { economyListings, standardListings, premiumListings } =
          classifyAndSortListingsByPrice(validAirbnbResults, quartiles);

        setEconomyListings(economyListings);
        setStandardListings(standardListings);
        setPremiumListings(premiumListings);
      } catch (error) {
        logger.error("Error fetching flight results:", error);
      } finally {
        setLoading(false);
        const durationMs = performance.now() - startTime; // Calculate duration in ms
        const durationSec = (durationMs / 1000).toFixed(2); // Convert to seconds
        logger.log(`Search Duration: ${durationSec} seconds`);
      }
    },
    [
      logSearchData,
      origin,
      destination,
      departureDate,
      returnDate,
      adults,
      children,
      sortBy,
      getEntityId,
      fetchAirbnbStays,
      selectCheapestItinerary,
      selectFastestItinerary,
      selectBestItinerary,
      flightType,
      airportsData,
      calculateQuartiles,
      classifyAndSortListingsByPrice,
      airbnbType,
      normalizeListingPrices, // Add this dependency
      handleStaySearch,
    ]
  );

  const handleFlightError = (error) => {
    if (error.response?.status === 502) {
      logger.error("Bad Gateway: The server is temporarily unavailable.");
    } else if (error.response?.status === 404) {
      logger.warn("No flights found.");
    } else {
      logger.error("An unexpected error occurred:", error);
    }
    setNoFlightsFound(true);
  };

  // Empty useEffect (can be removed if not needed)
  useEffect(() => {}, [economyListings, standardListings, premiumListings]);

  // Handle back to search
  const handleBackToSearch = (callback) => {
    setFlightResults(null);
    setLoading(false); // Reset loading state
    setSearchInitiated(false); // Return to the search form view
    setNoFlightsFound(false); // Reset no flights state

    if (origin) {
      setOriginInput(`${origin.name} (${origin.iata})`);
    }
    if (destination) {
      setDestinationInput(`${destination.name} (${destination.iata})`);
    }

    // Safely invoke the callback if it exists
    if (typeof callback === "function") {
      callback();
    }
  };

  return (
    <>
      <noscript>
        <div>
          <h1>FlyBnB: Flights & Airbnb Costs in One Smart Search</h1>
          <p>
            Plan trips effortlessly by combining flight prices and Airbnb
            listings. Quickly filter and find the best deals for your travel
            needs.
          </p>
        </div>
      </noscript>
      <Helmet>
        <title>FlyBnB: Flights & Airbnb Costs in One Smart Search</title>
        <meta
          name="description"
          content="Combine flight prices and Airbnb averages. Instantly see trip costs and listings auto-sorted by price categories."
        />
        <meta
          name="keywords"
          content="Flights, Airbnb, Travel, Trip Planning"
        />
      </Helmet>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Container
          maxWidth="md"
          sx={{
            textAlign: "center",
            minHeight: "50vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start", // Change from "center" to "flex-start"
            alignItems: "center", // Add this to center horizontally
            color: "text.primary",
            mt: 3,
            mb: 5, // Add some bottom margin for spacing
            px: 2, // Ensure padding doesn't cause overflow
          }}
        >
          {loading && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <CircularProgress size={80} thickness={4} color="primary" />
              <Typography
                variant="h4"
                sx={{
                  mt: 5,
                  fontFamily: "'Roboto', sans-serif",
                }}
              >
                {searchType === "staysOnly"
                  ? "Searching stays..."
                  : "Searching flights and stays..."}
              </Typography>
            </Box>
          )}

          {!loading && searchInitiated && (
            <Box>
              <CompactSearchForm
                origin={origin}
                setOrigin={setOrigin}
                originInput={originInput}
                setOriginInput={setOriginInput}
                originOptions={originOptions}
                destination={destination}
                setDestination={setDestination}
                destinationInput={destinationInput}
                setDestinationInput={setDestinationInput}
                destinationOptions={destinationOptions}
                departureDate={departureDate}
                setDepartureDate={setDepartureDate}
                returnDate={returnDate}
                setReturnDate={setReturnDate}
                adults={adults}
                setAdults={setAdults}
                handleSearch={handleSearch}
                flightType={flightType}
                handleBackToSearch={handleBackToSearch} // Add this line
                searchType={searchType}
              />
              {!(noAirbnbFound || noFlightsFound) && (
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "normal",
                    mt: 4,
                    mb: 0,
                    fontSize: {
                      xs: "1.3rem", // Font size for extra-small screens
                      sm: "1.5rem", // Small screens
                    },
                    width: {
                      xs: "80%", // Width for extra-small screens
                      sm: "100%", // Small screens
                    },
                    mx: "auto", // Center the text horizontally
                    textAlign: "center", // Center the text
                  }}
                >
                  {loading ? (
                    searchType === "staysOnly" ? (
                      <>
                        We're looking for stays in{" "}
                        <strong>
                          {destination?.city || "your destination"}
                        </strong>{" "}
                        for{" "}
                        <strong>
                          {returnDate && departureDate
                            ? Math.ceil(
                                (returnDate - departureDate) /
                                  (1000 * 60 * 60 * 24)
                              )
                            : "your selected"}
                        </strong>{" "}
                        days...
                      </>
                    ) : (
                      <>
                        We're looking for your{" "}
                        <strong>{origin?.city || "departure"}</strong> to{" "}
                        <strong>{destination?.city || "destination"}</strong>{" "}
                        travel flights & stays for{" "}
                        <strong>
                          {returnDate && departureDate
                            ? Math.ceil(
                                (returnDate - departureDate) /
                                  (1000 * 60 * 60 * 24)
                              )
                            : "your selected"}
                        </strong>{" "}
                        days...
                      </>
                    )
                  ) : searchType === "staysOnly" ? (
                    (() => {
                      const days = Math.ceil(
                        (returnDate - departureDate) / (1000 * 60 * 60 * 24)
                      );
                      const stayPrice =
                        selectedStayCategory === "economy"
                          ? quartiles.Q1
                          : selectedStayCategory === "standard"
                            ? quartiles.Q2
                            : quartiles.Q3;

                      const totalStayPrice = Math.ceil(stayPrice);

                      return destination ? (
                        <>
                          <strong>
                            {selectedStayCategory === "economy"
                              ? "Economy"
                              : selectedStayCategory === "standard"
                                ? "Standard"
                                : "Premium"}{" "}
                          </strong>{" "}
                          stays in <strong>{submittedDestination.city}</strong>{" "}
                          for <strong>{days}</strong> days with{" "}
                          <strong>{adults}</strong> adult{adults > 1 ? "s" : ""}
                          {children > 0 && (
                            <>
                              {" "}
                              & <strong>{children}</strong> child
                              {children > 1 ? "ren" : ""}
                            </>
                          )}{" "}
                          will cost on average{" "}
                          <span style={{ fontWeight: "bold", color: "white" }}>
                            ${totalStayPrice.toLocaleString()}.
                          </span>
                        </>
                      ) : (
                        <>
                          We're looking for stays at{" "}
                          <strong>
                            {destination?.city || "your destination"}
                          </strong>{" "}
                          for <strong>{days}</strong> days...
                        </>
                      );
                    })()
                  ) : (
                    (() => {
                      const stayPrice =
                        selectedStayCategory === "economy"
                          ? quartiles.Q1
                          : selectedStayCategory === "standard"
                            ? quartiles.Q2
                            : quartiles.Q3;

                      const flightPrice = flightResults?.[0]?.price?.formatted
                        ? Math.ceil(
                            parseFloat(
                              flightResults[0].price.formatted.replace(
                                /[^0-9.-]+/g,
                                ""
                              )
                            )
                          )
                        : NaN;

                      const totalPrice =
                        !isNaN(stayPrice) && !isNaN(flightPrice)
                          ? Math.ceil(stayPrice + flightPrice)
                          : NaN;

                      const days = Math.ceil(
                        (returnDate - departureDate) / (1000 * 60 * 60 * 24)
                      );

                      return totalPrice && origin && destination ? (
                        <>
                          <strong>{submittedOrigin.city}</strong> to{" "}
                          <strong>{submittedDestination.city}</strong> for{" "}
                          <strong>{days}</strong> days with{" "}
                          <strong>{adults}</strong> adult{adults > 1 ? "s" : ""}
                          {children > 0 && (
                            <>
                              {" "}
                              & <strong>{children}</strong> child
                              {children > 1 ? "ren" : ""}
                            </>
                          )}{" "}
                          will cost a total of{" "}
                          <span style={{ fontWeight: "bold", color: "white" }}>
                            ${totalPrice.toLocaleString()}.
                          </span>
                          <br />
                          The <strong>{sortBy}</strong> flight is{" "}
                          <strong>${flightPrice.toLocaleString()}</strong> and{" "}
                          <strong>{selectedStayCategory}</strong> stays average{" "}
                          <strong>
                            ${Math.ceil(stayPrice).toLocaleString()}
                          </strong>
                          .
                        </>
                      ) : (
                        <>
                          We're looking for your{" "}
                          <strong>
                            {submittedOrigin?.city || "departure"}
                          </strong>{" "}
                          to{" "}
                          <strong>
                            {submittedDestination?.city || "destination"}
                          </strong>{" "}
                          travel flights & stays for{" "}
                          <strong>
                            {submittedReturnDate && submittedDepartureDate
                              ? Math.ceil(
                                  (submittedReturnDate -
                                    submittedDepartureDate) /
                                    (1000 * 60 * 60 * 24)
                                )
                              : "your selected"}
                          </strong>{" "}
                          days...
                        </>
                      );
                    })()
                  )}
                </Typography>
              )}

              {/* Total Results Cards Top */}
              <Box
                sx={{
                  width: "100%", // Ensure it uses full width
                  maxWidth: "1200px", // Prevent overflow on larger screens
                  mx: "auto", // Center horizontally
                  px: 2, // Add padding but ensure it's within bounds
                  overflowX: "hidden", // Avoid horizontal scroll
                }}
              >
                <Results
                  bestItinerary={bestItinerary}
                  cheapestItinerary={cheapestItinerary}
                  fastestItinerary={fastestItinerary}
                  flightResults={flightResults}
                  sortBy={sortBy}
                  handleSortChange={handleSortChange}
                  generateSkyScannerLink={generateSkyScannerLink}
                  quartiles={quartiles}
                  selectedStayCategory={selectedStayCategory}
                  handleStayCategoryChange={handleStayCategoryChange}
                  economyListings={economyListings}
                  standardListings={standardListings}
                  premiumListings={premiumListings}
                  handleBackToSearch={handleBackToSearch}
                  departureDate={departureDate}
                  returnDate={returnDate}
                  handleClearStayFilter={handleClearStayFilter}
                  loading={loading}
                  airbnbType={airbnbType} // Pass it here
                  noAirbnbFound={noAirbnbFound}
                  noFlightsFound={noFlightsFound}
                  submittedOrigin={submittedOrigin}
                  submittedDestination={submittedDestination}
                  searchType={searchType}
                  minReviewCount={minReviewCount}
                  minReviewScore={minReviewScore}
                />
              </Box>
              <Button
                variant="contained"
                onClick={handleBackToSearch}
                sx={{
                  width: 100,
                  height: 50,
                  mt: 3,
                  backgroundColor: "#CBE11E",
                  color: "black",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  borderRadius: "8px",
                  "&:hover": {
                    backgroundColor: (theme) => darken("#CBE11E", 0.2), // Darken by 20%
                  },
                }}
              >
                Back
              </Button>
            </Box>
          )}

          {!loading && !searchInitiated && (
            <SearchForm
              originOptions={originOptions}
              originInput={originInput}
              setOriginInput={setOriginInput}
              origin={origin}
              setOrigin={setOrigin}
              destinationOptions={destinationOptions}
              destinationInput={destinationInput}
              setDestinationInput={setDestinationInput}
              destination={destination}
              setDestination={setDestination}
              departureDate={departureDate}
              setDepartureDate={setDepartureDate}
              returnDate={returnDate}
              setReturnDate={setReturnDate}
              adults={adults}
              setAdults={setAdults}
              children={children} // Add children prop
              setChildren={setChildren} // Add setChildren function
              filterAirports={filterAirports}
              CustomPickersDay={CustomPickersDayStyled}
              flightType={flightType} // Add this line
              setFlightType={setFlightType} // Add this line
              airbnbType={airbnbType}
              setAirbnbType={setAirbnbType}
              searchType={searchType}
              setSearchType={setSearchType}
              minReviewCount={minReviewCount}
              setMinReviewCount={setMinReviewCount}
              minReviewScore={minReviewScore}
              setMinReviewScore={setMinReviewScore}
              handleSearch={handleSearch}
            />
          )}
        </Container>
        {!loading && !searchInitiated && (
          <>
            {/* Add Divider here */}
            <Divider sx={{ my: 1, borderBottomWidth: 0, color: "#272727" }} />

            {/* HowItWorks Component */}
            <HowItWorks />
            <Container sx={{ mt: 5, mb: 5 }}>
              {" "}
              <Explainer />
            </Container>

            <Subscribe />

            <Divider sx={{ my: 1, borderBottomWidth: 0, color: "#272727" }} />

            <div
              id="contact"
              inert={!loading && !searchInitiated ? undefined : true}
            >
              <Container maxWidth="sm" sx={{ mt: 5, mb: 5 }}>
                <ContactForm />
              </Container>
            </div>
          </>
        )}{" "}
      </LocalizationProvider>
    </>
  );
};

export default Home;
