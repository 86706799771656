// SearchForm.js
import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Autocomplete,
  Typography,
} from "@mui/material";
import { PickersDay } from "@mui/x-date-pickers/PickersDay"; // Import PickersDay
import { FlightTakeoff, FlightLand, Add, Remove } from "@mui/icons-material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from "@mui/icons-material/Home";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import StarIcon from "@mui/icons-material/Star";
import CommentIcon from "@mui/icons-material/Comment";
import { Menu, MenuItem } from "@mui/material"; // Import Menu and MenuItem
import { lighten } from "@mui/system";

const CustomPickersDay = (props) => {
  const { day, departureDate, ...other } = props;
  const isDepartureDay =
    departureDate && day.toDateString() === departureDate.toDateString();

  return (
    <PickersDay
      {...other}
      sx={{
        ...(isDepartureDay && {
          backgroundColor: "primary.main",
          color: "white",
          "&:hover, &:focus": {
            backgroundColor: "primary.dark",
          },
        }),
      }}
    />
  );
};

/**
 * SearchForm Component
 * Handles flight search with validation for origin, destination, dates, and adults.
 */

const SearchForm = ({
  originOptions,
  originInput,
  setOriginInput,
  origin,
  setOrigin,
  destinationOptions,
  destinationInput,
  setDestinationInput,
  destination,
  setDestination,
  departureDate,
  setDepartureDate,
  returnDate,
  setReturnDate,
  adults,
  setAdults,
  children,
  setChildren,
  filterAirports,
  flightType,
  setFlightType,
  airbnbType,
  setAirbnbType,
  searchType,
  setSearchType,
  minReviewCount,
  setMinReviewCount,
  minReviewScore,
  setMinReviewScore,
  handleSearch,
}) => {
  const [errors, setErrors] = useState({});
  const [isDepartureOpen, setIsDepartureOpen] = useState(false);
  const [isReturnOpen, setIsReturnOpen] = useState(false);
  const [tripTypeAnchor, setTripTypeAnchor] = useState(null);
  const [adultsAnchor, setAdultsAnchor] = useState(null);

  const validateFields = () => {
    const newErrors = {};

    if (searchType === "flightsAndStays") {
      if (!origin) newErrors.origin = "Origin is required";
      if (!destination) newErrors.destination = "Destination is required";
      if (!departureDate)
        newErrors.departureDate = "Departure date is required";
      if (origin && destination && origin.city === destination.city) {
        newErrors.origin = "Origin and destination cannot be the same";
        newErrors.destination = "Origin and destination cannot be the same";
      }

      // Validate return date for roundtrip flights
      if (
        flightType === "roundtrip" &&
        (!returnDate || returnDate < departureDate)
      ) {
        newErrors.returnDate = "Return date must be after the departure date";
      }
    }

    if (searchType === "staysOnly") {
      if (!destination) newErrors.destination = "Destination is required";
      if (!departureDate) newErrors.departureDate = "Check-in date is required";
      if (!returnDate || returnDate < departureDate) {
        newErrors.returnDate =
          "Check-out date is required and must be after check-in date";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleFormSubmit = () => {
    if (validateFields()) {
      handleSearch(searchType); // Pass the current searchType directly
    }
  };

  return (
    <>
      <Box
        sx={{
          width: {
            xs: "320px", // Mobile
            sm: "550px", // Halfscreen
            md: "800px", // Desktop
          },
          position: "relative",
        }}
      >
        {/* Hero Title */}
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontWeight: "900",
            mb: 2,
            fontFamily: "'Roboto', sans-serif",
            textAlign: "center",
            color: "white",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
            letterSpacing: "1.5px",
            lineHeight: "1.2",
            fontSize: {
              xs: "2.5rem", // Mobile font size
              sm: "2.5rem", // Halfscreen
              md: "3rem", // Desktop
            },
          }}
        >
          One Search for{" "}
          <Box component="span" sx={{ color: "primary.flight" }}>
            Flights
          </Box>{" "}
          &{" "}
          <Box component="span" sx={{ color: "primary.stay" }}>
            Airbnb
          </Box>
        </Typography>

        {/* Hero Subtitle */}
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            fontWeight: "300",
            color: "silver",
            mb: 4,
            mt: 2,
            fontFamily: "'Roboto', sans-serif",
            textAlign: "center",
            maxWidth: "1200px",
            mx: "auto",
            fontSize: {
              xs: "1rem", // Mobile font size
              sm: "0.9rem", // Halfscreen
              md: "1.25rem", // Desktop
            },
          }}
        >
          FlyBnB combines flight prices and Airbnb averages in seconds.
          Instantly see total trip costs and explore Airbnb listings auto-sorted
          into price categories. Try below for free today!
        </Typography>
      </Box>

      {/* Form Container */}
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "1200px",
          mx: "auto",
          mt: 0,

          mb: 2,
          gap: 2,
        }}
      >
        {/* Search Type Selection */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,
            width: "100%",
          }}
        >
          <ToggleButtonGroup
            value={searchType}
            exclusive
            onChange={(e, newValue) => {
              if (newValue !== null) setSearchType(newValue); // update parent's state
            }}
            sx={{
              width: {
                xs: "90%", // Smaller width for extra-small screens
                md: "380px", // Default width for medium and larger
              },
              height: {
                xs: "50px", // Shorter height on xs
                md: "50px", // Normal height on md and larger
              },
              borderRadius: "20px",
              overflow: "hidden",
              boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
              border: "1.2px solid gray",
              "& .MuiToggleButton-root": {
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "500",
                fontSize: {
                  xs: "0.75rem", // Smaller font size on extra-small screens
                  md: "0.808rem", // Default font size on medium and larger screens
                },
                color: "#858383",
                transition: "all 0.2s ease",
                border: "none",
                outline: "none",
                "&.Mui-selected": {
                  backgroundColor: "primary.main",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "primary.main", // Slightly darker hover effect for selected
                  },
                },
                "&:focus": {
                  backgroundColor: "primary.main", // Maintain selected color on focus
                  color: "white",
                },
                "&:active": {
                  backgroundColor: "primary.main", // Active state for touch feedback
                  color: "white",
                },
              },
            }}
          >
            <ToggleButton
              value="flightsAndStays"
              aria-label="Flights & Stays"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1, // Space between image and text
              }}
            >
              <img
                src="/bothwhite-icon.svg"
                alt="Flights Icon"
                style={{ width: 50, height: 40 }}
              />
              Flights & Stays
            </ToggleButton>
            <ToggleButton
              value="staysOnly"
              aria-label="Stays Only"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1, // Space between image and text
              }}
            >
              <img
                src="/staywhite-icon.svg"
                alt="Stays Icon"
                style={{ width: 50, height: 45 }}
              />
              Stays Only
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* FlightType and Adults Box */}
        <Box
          sx={{
            display: "flex",

            alignItems: "center",
            justifyContent: "center", // Center content like the origin box
            width: "100%", // Ensure the full width for alignment
            maxWidth: "1200px", // Align with your form fields
            mx: "auto", // Center horizontally
            mt: 0,
            mb: 0, // Match spacing consistency
            gap: 0.5, // Space between buttons
          }}
        >
          {/* Flight Type Dropdown Selector */}
          {searchType !== "staysOnly" && (
            <Button
              variant="outlined"
              onClick={(e) => setTripTypeAnchor(e.currentTarget)} // Open dropdown
              endIcon={<ExpandMoreIcon />} // Dropdown arrow
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "130px", // Fixed width for the button
                textTransform: "none",
                fontWeight: "bold",
                color: "white",
                borderColor: "#121212",
                borderRadius: "10px",
                backgroundColor: lighten("#121212", 0.1),
                "&:hover": { backgroundColor: "primary.main" },
              }}
            >
              {flightType === "roundtrip" ? "Round trip" : "One way"}
            </Button>
          )}

          {/* Adults Dropdown */}
          <Button
            variant="outlined"
            onClick={(e) => setAdultsAnchor(e.currentTarget)} // Open dropdown menu
            endIcon={<ExpandMoreIcon />}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "130px", // Ensure this is the correct width for alignment
              textTransform: "none",
              fontWeight: "bold",
              color: "white",
              borderColor: "#121212",
              borderRadius: "10px",
              backgroundColor: lighten("#121212", 0.1),
              "&:hover": { backgroundColor: "primary.main" },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <PersonIcon sx={{ fontSize: "1.2rem" }} />
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {adults + children} {/* Total of adults and children */}
              </Typography>
            </Box>
          </Button>
        </Box>

        {/* Dropdown Menus */}
        <Menu
          anchorEl={tripTypeAnchor}
          open={Boolean(tripTypeAnchor)} // Open condition
          onClose={() => setTripTypeAnchor(null)} // Close menu
          MenuListProps={{ "aria-labelledby": "trip-type-selector" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#2c2c2c",
              color: "white",
              minWidth: "150px", // Matches button width
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.3)", // Subtle shadow
            },
          }}
        >
          <MenuItem
            onClick={() => {
              setFlightType("roundtrip");
              setTripTypeAnchor(null);
            }}
            selected={flightType === "roundtrip"}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "primary.main",
                color: "white",
              },
              "&:hover": {
                backgroundColor: "primary.light",
              },
            }}
          >
            Round Trip
          </MenuItem>
          <MenuItem
            onClick={() => {
              setFlightType("oneway");
              setTripTypeAnchor(null);
            }}
            selected={flightType === "oneway"}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "primary.main",
                color: "white",
              },
              "&:hover": {
                backgroundColor: "primary.light",
              },
            }}
          >
            One Way
          </MenuItem>
        </Menu>

        {/* Adults Menu */}
        <Menu
          anchorEl={adultsAnchor}
          open={Boolean(adultsAnchor)}
          onClose={() => setAdultsAnchor(null)}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#2c2c2c",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.3)",
              minWidth: "200px",
              padding: 0,
            },
          }}
        >
          {/* Adults */}
          <MenuItem
            disableRipple
            sx={{
              display: "flex",
              justifyContent: "space-between",
              "&:hover": {
                backgroundColor: "transparent", // Removes hover effect
              },
              "&.Mui-focusVisible": {
                backgroundColor: "transparent", // Removes focus effect
              },
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Adults
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <IconButton
                onClick={() => setAdults(Math.max(1, adults - 1))}
                size="small"
                sx={{ color: "white" }}
              >
                <Remove />
              </IconButton>
              <Typography>{adults}</Typography>
              <IconButton
                onClick={() => setAdults(Math.min(10, adults + 1))} // Max cap at 10
                size="small"
                sx={{ color: "white" }}
              >
                <Add />
              </IconButton>
            </Box>
          </MenuItem>

          {/* Children */}
          <MenuItem
            disableRipple
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%", // Full width for consistent layout
              "&:hover": {
                backgroundColor: "transparent", // Removes hover effect
              },
              "&.Mui-focusVisible": {
                backgroundColor: "transparent", // Removes focus effect
              },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Children
              </Typography>
              <Typography
                variant="caption"
                sx={{ color: "text.secondary", mt: -0.5 }}
              >
                Ages 2-12
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <IconButton
                onClick={() => setChildren(Math.max(0, children - 1))} // Min cap at 0
                size="small"
                sx={{ color: "white" }}
              >
                <Remove />
              </IconButton>
              <Typography>{children}</Typography>
              <IconButton
                onClick={() => setChildren(Math.min(10, children + 1))} // Max cap at 10
                size="small"
                sx={{ color: "white" }}
              >
                <Add />
              </IconButton>
            </Box>
          </MenuItem>
        </Menu>

        {/* Origin and Destination Autocomplete Fields */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 2, md: 1 },
            width: "100%",
            maxWidth: "1200px",
            justifyContent: "center",
            alignItems: "center",
            mx: "auto",
            mt: { xs: 1, md: 0.5 },
          }}
        >
          {/* Origin Autocomplete */}
          {searchType !== "staysOnly" && (
            <Autocomplete
              options={originOptions}
              getOptionLabel={(option) => `${option.city}, ${option.country}`}
              inputValue={originInput}
              onInputChange={(e, newInputValue) => {
                setOriginInput(newInputValue);
                if (newInputValue === "") {
                  setOrigin(null); // Clear the selected origin when input is empty
                }
              }}
              value={origin}
              onChange={(e, newValue) => setOrigin(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Origin"
                  error={!!errors.origin}
                  helperText={errors.origin}
                  placeholder="Type city or airport code"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <FlightTakeoff />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              filterOptions={(options, { inputValue }) =>
                filterAirports(inputValue)
              }
              sx={{
                flex: 1,
                minWidth: { xs: "99%", md: "49.5%" },
                maxWidth: "300px",
              }}
            />
          )}

          {/* Destination Autocomplete */}
          <Autocomplete
            options={destinationOptions}
            getOptionLabel={(option) => `${option.city}, ${option.country}`}
            inputValue={destinationInput}
            onInputChange={(e, newInputValue) => {
              setDestinationInput(newInputValue);
              if (newInputValue === "") {
                setDestination(null); // Clear the selected destination when input is empty
              }
            }}
            value={destination}
            onChange={(e, newValue) => setDestination(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Destination"
                error={!!errors.destination}
                helperText={errors.destination}
                placeholder="Type city or airport code"
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <FlightLand />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            filterOptions={(options, { inputValue }) =>
              filterAirports(inputValue)
            }
            sx={{
              flex: 1,
              minWidth:
                searchType === "staysOnly"
                  ? { xs: "100%", md: "100%" }
                  : { xs: "99%", md: "49.5%" },
              maxWidth: "400px",
            }}
          />
        </Box>
        {/* Date Pickers */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 1.5, md: 1 },
            width: "100%",
            maxWidth: "1200px",
            justifyContent: "center",
            alignItems: "center",
            mx: "auto",
          }}
        >
          {/* Departure Date Picker */}
          <DesktopDatePicker
            label={
              searchType === "staysOnly" ? "Check-In Date" : "Departure Date"
            }
            value={departureDate}
            minDate={new Date()}
            onChange={(newValue) => setDepartureDate(newValue)}
            open={isDepartureOpen}
            onOpen={() => setIsDepartureOpen(true)}
            onClose={() => setIsDepartureOpen(false)}
            disablePortal
            disableCloseOnSelect={false} // Ensures calendar closes on selection
            renderDay={(day, selectedDates, pickersDayProps) => (
              <CustomPickersDay
                {...pickersDayProps}
                day={day}
                departureDate={departureDate}
              />
            )}
            slots={{
              textField: TextField, // Specify TextField as the component for textField
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: "outlined",
                inputProps: {
                  readOnly: true,
                  onClick: () => setIsDepartureOpen(true), // Open calendar on click
                },
                error: !!errors.departureDate,
                helperText: errors.departureDate,
              },
            }}
            onError={(reason, value) => {
              if (reason === "invalidDate") {
                setErrors((prev) => ({
                  ...prev,
                  departureDate: "Please enter a valid departure date",
                }));
              } else {
                // Clear the error if the reason is not 'invalidDate'
                setErrors((prev) => ({
                  ...prev,
                  departureDate: undefined,
                }));
              }
            }}
            sx={{
              flex: 1,
              minWidth: { xs: "99%", md: "50%" },
              maxWidth: "300px",
            }}
          />

          {/* Return Date Picker (Visible Only for Roundtrip) */}
          {(flightType === "roundtrip" ||
            searchType === "staysOnly" ||
            flightType === "oneway") && (
            <DesktopDatePicker
              label={
                searchType === "staysOnly" || flightType === "oneway"
                  ? "Check-Out Date"
                  : "Return Date"
              } // Dynamically set the label
              value={returnDate}
              minDate={
                departureDate
                  ? new Date(departureDate.getTime() + 24 * 60 * 60 * 1000)
                  : new Date()
              }
              onChange={(newValue) => setReturnDate(newValue)}
              open={isReturnOpen}
              onOpen={() => setIsReturnOpen(true)}
              onClose={() => setIsReturnOpen(false)}
              disablePortal
              disableCloseOnSelect={false} // Ensures calendar closes on selection
              renderDay={(day, selectedDates, pickersDayProps) => (
                <CustomPickersDay
                  {...pickersDayProps}
                  day={day}
                  departureDate={departureDate}
                />
              )}
              slots={{ textField: TextField }} // Use MUI's default TextField behavior
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "outlined",
                  inputProps: {
                    readOnly: true, // Prevent manual input
                    onClick: () => setIsReturnOpen(true), // Open calendar on click
                  },
                  error: !!errors.returnDate,
                  helperText: errors.returnDate,
                },
              }}
              onError={(reason) => {
                if (reason === "invalidDate") {
                  setErrors((prev) => ({
                    ...prev,
                    returnDate: "Please enter a valid return date",
                  }));
                } else if (reason === "minDate") {
                  setErrors((prev) => ({
                    ...prev,
                    returnDate: "Return date cannot be before departure date",
                  }));
                } else {
                  setErrors((prev) => ({
                    ...prev,
                    returnDate: undefined,
                  }));
                }
              }}
              sx={{
                flex: 1,
                minWidth: { xs: "99%", md: "50%" },
                maxWidth: "300px",
              }}
            />
          )}
        </Box>

        {/* Stay Options BOX */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "100%",
            maxWidth: "1200px",
            mx: "auto",
            mt: 0,
            mb: 0,
            flexWrap: "nowrap", // Prevent wrapping
            flexDirection: { xs: "column", md: "row" }, // New line
          }}
        >
          {/* Airbnb Type Toggle Group with Icon */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flex: 1,
              mb: { xs: 1, md: 0 },
              width: { xs: "100%", md: "50%" }, // Full width on small screens, half on larger
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                mb: 0.75,
              }}
            >
              <HomeIcon sx={{ color: "white" }} />
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "normal",
                  color: "text.primary",
                  letterSpacing: "0.5px",
                }}
              >
                Type of Stay
              </Typography>
            </Box>

            <ToggleButtonGroup
              value={airbnbType}
              exclusive
              onChange={(e, newValue) => {
                if (newValue !== null) setAirbnbType(newValue);
              }}
              sx={{
                width: { xs: "90%", md: "75%" },
                borderRadius: "8px",
                overflow: "hidden",
                "& .MuiToggleButton-root": {
                  flex: 1,
                  textTransform: "none",
                  fontSize: "0.9rem",
                  padding: { xs: "12px 16px", md: "12px 16px" },
                  border: "2px solid",
                  borderColor: "divider",
                  whiteSpace: "nowrap",
                  "&.Mui-selected": {
                    backgroundColor: "primary",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "primary",
                    },
                  },
                  "&:not(:first-of-type)": {
                    borderLeft: "none",
                  },
                  "&:first-of-type": {
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: "8px",
                  },
                  "&:last-of-type": {
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                  },
                },
              }}
            >
              <ToggleButton value="both" aria-label="Any">
                Any
              </ToggleButton>
              <ToggleButton value="entire_home" aria-label="Entire Home">
                Entire Home
              </ToggleButton>
              <ToggleButton value="private_room" aria-label="Private Room">
                Private Room
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {/* New Review Score/Count Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              width: { xs: "100%", md: "50%" }, // Full width on small screens, half on larger
              alignItems: "center",
              justifyContent: "space-evenly",
              gap: 0,
              mx: "auto",
              flexWrap: "nowrap", // Prevent wrapping
              mt: { xs: 1, md: 0 },
              mb: 0,
              textAlign: "center",
            }}
          >
            {/* Min. Rating */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mb: { xs: 2, md: 0 },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <StarIcon sx={{ color: "white", fontSize: "17px" }} />{" "}
                {/* Adjust fontSize */}
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "normal",
                    color: "text.primary",
                    letterSpacing: "0.5px",
                  }}
                >
                  Min. Stay Rating
                </Typography>
              </Box>

              <Box sx={{ display: "center", alignItems: "center", gap: 1 }}>
                <TextField
                  value={minReviewScore} // Keep string input for user flexibility
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow only valid patterns: empty or numbers 1.00 to 4.99
                    if (value === "" || /^[1-4](\.\d{0,2})?$/.test(value)) {
                      setMinReviewScore(value); // Keep it as string for better typing experience
                    }
                  }}
                  onBlur={() => {
                    // Convert to number or reset to default on blur
                    const numericValue = parseFloat(minReviewScore);
                    if (
                      isNaN(numericValue) ||
                      numericValue < 1 ||
                      numericValue >= 5
                    ) {
                      setMinReviewScore("4.00"); // Default if invalid
                    } else {
                      setMinReviewScore(numericValue.toFixed(2)); // Format correctly
                    }
                  }}
                  type="text"
                  inputProps={{
                    inputMode: "decimal",
                    pattern: "^[1-4](\\.\\d{0,2})?$", // Restrict input
                    style: { textAlign: "center" }, // Center the text horizontally
                  }}
                  sx={{ width: "65px" }}
                />
              </Box>
            </Box>

            {/* Min. Reviews */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <CommentIcon sx={{ color: "white", fontSize: "17px" }} />{" "}
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "normal",
                    color: "text.primary",
                    letterSpacing: "0.5px",
                  }}
                >
                  Min. Stay Reviews
                </Typography>
              </Box>
              <Box sx={{ display: "center", alignItems: "center", gap: 1 }}>
                <TextField
                  value={minReviewCount}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow empty input temporarily and enforce upper limit
                    if (
                      value === "" ||
                      (Number.isInteger(+value) &&
                        +value >= 0 &&
                        +value <= 5000)
                    ) {
                      setMinReviewCount(
                        value === "" ? "" : parseInt(value, 10)
                      );
                    }
                  }}
                  onBlur={() => {
                    if (minReviewCount === "" || minReviewCount < 0) {
                      setMinReviewCount(5); // Reset to 0 if empty or invalid
                    } else if (minReviewCount > 5000) {
                      setMinReviewCount(5000); // Cap the max at 5000
                    }
                  }}
                  type="text"
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*", // Restrict input to numbers
                    style: { textAlign: "center" }, // Center the text horizontally
                  }}
                  sx={{ width: "65px" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Search Button */}
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{
            mt: 2,
            width: "100%",
            maxWidth: "200px",
          }}
          onClick={handleFormSubmit}
        >
          Search
        </Button>
      </Box>
    </>
  );
};

export default SearchForm;
