import React from "react";
import { Container, Typography, Box } from "@mui/material";

const About = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        textAlign: "center",
        mt: 5,
        mb: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
      }}
    >
      <Typography
        variant="h3"
        component="h1"
        sx={{
          fontWeight: "bold",
          lineHeight: 1.8,
          textAlign: "center",
        }}
      >
        About Us
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: "1.2rem",
          fontWeight: "bold",
          lineHeight: 1.8,
          textAlign: "center",
        }}
      >
        {" "}
        Hi, we’re Mo and Rafa from Ours2Discover!{" "}
        <a
          href="https://youtube.com/@ours2discover"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#1976d2", // Match link color to Material-UI primary color
            textDecoration: "none", // Remove underline for a clean look
          }}
        >
          <br></br>
          (Check out our YouTube channel)
        </a>
      </Typography>

      <Typography
        variant="body1"
        sx={{ fontSize: "1.1rem", lineHeight: 1.8, textAlign: "justify" }}
      >
        {" "}
        We’re a couple who turned our dream of long-term travel into a reality,
        embarking on a journey fueled by years of saving and planning. From
        bustling cities to serene landscapes, the adventure of exploring the
        world together brought us closer while also revealing how overwhelming
        trip planning could be.
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 3,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <img
            src="/Cappadocia.jpeg"
            alt="Watching the hot air balloons of Cappadocia, Turkey"
            title="Watching the hot air balloons of Cappadocia, Turkey"
            style={{
              width: "100%",
              maxWidth: "300px",
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          />
          <Typography
            variant="caption"
            sx={{ display: "block", mt: 1, fontSize: "0.9rem", color: "gray" }}
          >
            Watching the hot air balloons - Cappadocia, Turkey
          </Typography>
        </Box>

        <Box>
          <img
            src="/Prague.jpeg"
            alt="Enjoying a Bohemian castle - Prague, Czechia."
            title="Enjoying a Bohemian castle - Prague, Czechia"
            style={{
              width: "100%",
              maxWidth: "300px",
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          />
          <Typography
            variant="caption"
            sx={{ display: "block", mt: 1, fontSize: "0.9rem", color: "gray" }}
          >
            Enjoying a Bohemian castle - Prague, Czechia
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="body1"
        sx={{ fontSize: "1.1rem", lineHeight: 1.8, textAlign: "justify" }}
      >
        {" "}
        Finding our next destination often felt like a full-time job—comparing
        flights, accommodations, and costs across multiple platforms. The stress
        of juggling spreadsheets and endless tabs inspired us to create FlyBnB,
        a simple tool that combines flights and stays into one seamless search.
        Our goal? To help travelers like us spend less time planning and more
        time experiencing the world.
      </Typography>

      <Typography
        variant="body1"
        sx={{ fontSize: "1.1rem", lineHeight: 1.8, textAlign: "justify" }}
      >
        {" "}
        Through FlyBnB, we aim to share what we’ve learned and make travel
        planning accessible, efficient, and enjoyable for everyone. Whether it’s
        your first trip or your fiftieth, we’re here to make it easier. Thanks
        for being part of this journey!
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 3,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <img
            src="/Budapest.jpeg"
            alt="Exploring ruin bars - Budapest, Hungary"
            title="Exploring ruin bars - Budapest, Hungary"
            style={{
              width: "100%",
              maxWidth: "300px",
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          />
          <Typography
            variant="caption"
            sx={{ display: "block", mt: 1, fontSize: "0.9rem", color: "gray" }}
          >
            Exploring ruin bars - Budapest, Hungary
          </Typography>
        </Box>
        <Box>
          <img
            src="/Skye.jpeg"
            alt="Hiking up Old Man of Storr in Isle of Skye, Scotland."
            title="Hiking Old Man of Storr in Isle of Skye, Scotland"
            style={{
              width: "100%",
              maxWidth: "300px",
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          />
          <Typography
            variant="caption"
            sx={{ display: "block", mt: 1, fontSize: "0.9rem", color: "gray" }}
          >
            Hiking up Old Man of Storr - Isle of Skye, Scotland
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default About;
